import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UIActions from 'actions/UIActions';
import {
  getProspectiveProperty,
  getDefaultExistingProperty,
} from 'shared/lib/propertyHelper';
import { lowestRateDisclaimer } from 'lib/disclaimerHelper';
import { toForkOrSignup, TO_ABOUT_THE_PROPERTY } from 'lib/pathHelper';

import sanityCheck from 'hocs/sanityCheck';

import {
  LOAN_PURPOSE_INVESTMENT,
  LOAN_PURPOSE_RESIDENTIAL,
} from 'shared/constants/loanPurposes';

import CashFlowReportContent from 'components/CashFlowReportContent/CashFlowReportContent';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import View from 'components/View/View';
import ButtonNext from 'components/ButtonNext/ButtonNext';

const messages = defineMessages({
  titlePositive: {
    id: 'CashFlowReport.titlePositive',
    defaultMessage:
      'You’d make about {availableCash, number, currency} per week after covering typical costs & interest payments.',
  },
  titleNegative: {
    id: 'CashFlowReport.titleNegative',
    defaultMessage:
      'You’ll need to contribute about {availableCash, number, currency} per week to help cover typical costs & interest payments.',
  },
});

export class CashFlowReportComponent extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    scenario: PropTypes.object.isRequired,
    goToPathWithAnimation: PropTypes.func.isRequired,
  };

  static isRequiredStructure = (props) => {
    const { scenario } = props;

    switch (scenario.loanPurpose) {
      case LOAN_PURPOSE_INVESTMENT:
        return !!getProspectiveProperty(scenario);
      case LOAN_PURPOSE_RESIDENTIAL:
        return !!getDefaultExistingProperty(scenario);
      default:
        return false;
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  getProperty = (scenario) =>
    scenario.loanPurpose === LOAN_PURPOSE_INVESTMENT
      ? getProspectiveProperty(scenario)
      : getDefaultExistingProperty(scenario);

  nextStep = () => {
    const { scenario, goToPathWithAnimation } = this.props;
    if (scenario.loanPurpose === LOAN_PURPOSE_INVESTMENT) {
      goToPathWithAnimation(toForkOrSignup());
    } else {
      goToPathWithAnimation(TO_ABOUT_THE_PROPERTY);
    }
  };

  render() {
    const {
      scenario,
      intl: { formatMessage },
    } = this.props;
    const property = this.getProperty(scenario);

    const investmentLowestRate =
      property.investmentLowestRateManual ||
      (property.lowestRateProduct && property.lowestRateProduct.interestRate);
    const disclaimer = [
      lowestRateDisclaimer(investmentLowestRate, property.ownerOccupied),
    ];
    const availableCash = property.totalCashFlow;
    const title = formatMessage(
      availableCash > 0 ? messages.titlePositive : messages.titleNegative,
      { availableCash: Math.abs(availableCash) },
    );
    return (
      <View inverseHeader footerDisclaimer={disclaimer}>
        <ContentsWrapper id='cashFlowReport' title={title} inverseHeader>
          <CashFlowReportContent property={property} isOpenByDefault>
            <ButtonNext id='next' onClick={this.nextStep} />
          </CashFlowReportContent>
        </ContentsWrapper>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({ scenario: state.scenario });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPathWithAnimation: UIActions.goToPathWithAnimation,
    },
    dispatch,
  );

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(sanityCheck(CashFlowReportComponent)),
);
