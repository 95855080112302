import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

import scenarioActions from 'actions/scenarioActions';

import loanPurposes from 'constants/loanPurposesUI';

import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import Question from 'components/Question/Question';
import View from 'components/View/View';
import ButtonList from 'components/ButtonList/ButtonList';

const messages = defineMessages({
  title: {
    id: 'GetStarted.title',
    defaultMessage: 'Ok. Let’s get started.',
  },
  loanPurposeQuestionLabel: {
    id: 'GetStarted.loanPurposeQuestionLabel',
    defaultMessage: 'How can we help you today?',
  },
});

class GetStarted extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    setLoanPurpose: PropTypes.func.isRequired,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { intl, setLoanPurpose } = this.props;
    return (
      <View>
        <ContentsWrapper
          id='propertyGoals'
          title={intl.formatMessage(messages.title)}
        >
          <Question
            label={intl.formatMessage(messages.loanPurposeQuestionLabel)}
            id='loanPurpose'
            formCompleted
            direction='column'
          >
            <ButtonList
              action={setLoanPurpose}
              items={loanPurposes(intl.formatMessage)()}
            />
          </Question>
        </ContentsWrapper>
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLoanPurpose: scenarioActions.setLoanPurpose,
    },
    dispatch,
  );

export default injectIntl(connect(null, mapDispatchToProps)(GetStarted));
