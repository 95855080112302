import React from 'react';
import { Switch, Route } from 'react-router';
import PropTypes from 'prop-types';

import {
  HELP_PATH,
  NOT_FOUND_PATH,
  HELP_SHARED_LOGIN_PATH,
  HELP_EXPIRED_SESSION_PATH,
  HELP_INVALID_PRIVACY_POLICY_PATH,
  HELP_ALREADY_EXECUTED_PRIVACY_POLICY_PATH,
} from 'shared/constants/paths';
import AUTH_INTENTS from 'constants/authIntents';
import { LOGIN_EXPIRED_ERROR_MESSAGE } from 'constants/messages/authError';

import HelpPage from 'containers/HelpPage';
import themes from 'components/FullBgContentsWrapper/FullBgContentsWrapper.css';
import { RouteForNonLoggedin, routeComponentPropTypes } from './helpers';

import propsMixin from 'hocs/propsMixin';
import locale from 'config/locale';

const HelpSharedLoginPage = () => {
  return (
    <HelpPage
      theme={themes.sharedLoginFailed}
      title={LOGIN_EXPIRED_ERROR_MESSAGE}
      showLogin={false}
      showConfirmEmail
    />
  );
};

const HelpExpiredSessionPage = ({ intent, redirect }) => (
  <HelpPage
    theme={themes.sharedLoginFailed}
    title={`Let's ${
      intent === AUTH_INTENTS.SIGNUP ? 'sign up' : 'log in'
    } again!`}
    description='We noticed your profile was not being used for a while. We logged you out to keep your information safe.'
    intent={intent}
    redirect={redirect}
    showLogin={false}
    showLoginButton
  />
);

HelpExpiredSessionPage.propTypes = {
  intent: PropTypes.string,
  redirect: PropTypes.string,
};

const HelpPrivacyPolicyPage = ({ isAlreadyExecuted }) => (
  <HelpPage
    theme={themes.sharedLoginFailed}
    title={
      isAlreadyExecuted
        ? 'You have already accepted the privacy policy'
        : 'We can’t seem to find your privacy policy'
    }
    description={`Get in touch with your Mortgage ${
      locale.isAU ? 'Broker' : 'Adviser'
    } and they’ll send you a new privacy policy email`}
  />
);

const HelpPageNotFound = () => (
  <HelpPage
    theme={themes.sharedLoginFailed}
    title='We’re sorry, the page you’re looking for has not been found'
    description='Please contact your broker for assistance.'
    showLogin={false}
    showLoginButton={false}
    showContactInfo={false}
  />
);

HelpPrivacyPolicyPage.propTypes = {
  isAlreadyExecuted: PropTypes.bool,
};

const HelpPageRoutes = (props) => {
  const { match, location } = props;
  const { query: { intent, redirect } = {} } = location;
  return (
    <Switch>
      <Route exact path={match.path} component={HelpPage} />
      <RouteForNonLoggedin
        exact
        path={`${HELP_PATH}${NOT_FOUND_PATH}`}
        component={HelpPageNotFound}
      />
      <RouteForNonLoggedin
        exact
        path={HELP_SHARED_LOGIN_PATH}
        component={HelpSharedLoginPage}
      />
      <RouteForNonLoggedin
        path={HELP_EXPIRED_SESSION_PATH}
        component={propsMixin(HelpExpiredSessionPage, { intent, redirect })}
      />
      <RouteForNonLoggedin
        path={HELP_INVALID_PRIVACY_POLICY_PATH}
        component={HelpPrivacyPolicyPage}
      />
      <RouteForNonLoggedin
        path={HELP_ALREADY_EXECUTED_PRIVACY_POLICY_PATH}
        component={propsMixin(HelpPrivacyPolicyPage, {
          isAlreadyExecuted: true,
        })}
      />
    </Switch>
  );
};

HelpPageRoutes.propTypes = routeComponentPropTypes;

export default HelpPageRoutes;
