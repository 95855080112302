import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { isFunction } from 'lodash';

import View from 'components/View/View';
import Spinner from 'components/Spinner/Spinner';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import ProgressSection from 'components/ProgressSection/ProgressSection';
import ProgressItem from 'components/ProgressItem/ProgressItem';
import ButtonNext from 'components/ButtonNext/ButtonNext';

import loanApplicationActions from 'actions/loanApplicationActions';
import * as UISelectors from 'selectors/UISelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';

import {
  progressItems,
  PROGRESS_STATUS_COMPLETE,
  PROGRESS_STATUS_IN_PROGRESS,
  PROGRESS_STATUS_NEXT_STEP,
  PROGRESS_STATUS_NOT_STARTED_YET,
} from 'constants/applicationProgress';
import { DASHBOARD_BASE_PATH } from 'shared/constants/paths';
import commonMessages from 'constants/commonMessages';

export const messages = defineMessages({
  title: {
    id: 'ApplicationProgress.title',
    defaultMessage: 'Next steps',
  },
});

function getProgressStatus(index, status) {
  if (index < status) {
    return PROGRESS_STATUS_COMPLETE;
  }
  if (index === status) {
    return PROGRESS_STATUS_IN_PROGRESS;
  }
  if (index === status + 1) {
    return PROGRESS_STATUS_NEXT_STEP;
  }
  return PROGRESS_STATUS_NOT_STARTED_YET;
}

export class ApplicationProgress extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    match: PropTypes.object.isRequired,
    isSpinnerLoading: PropTypes.bool,
    status: PropTypes.number.isRequired,
    isShared: PropTypes.bool.isRequired,
    requestLoanApplicationStatus: PropTypes.func.isRequired,
  };

  static renderBody(body) {
    return body && body.map((s) => <p key={s}>{s}</p>);
  }

  static renderList(list) {
    return (
      list && (
        <ul>
          {list.map((s) => (
            <li key={s}>{s}</li>
          ))}
        </ul>
      )
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      status: 1,
    };
  }

  componentDidMount() {
    const { requestLoanApplicationStatus } = this.props;

    requestLoanApplicationStatus();

    window.scrollTo(0, 0);
  }

  renderProgressItem = (p, i) => {
    const {
      intl,
      status,
      match: {
        params: { loanApplicationId },
      },
    } = this.props;
    const subtitle = isFunction(p.subtitle)
      ? p.subtitle(loanApplicationId)
      : p.subtitle;

    return (
      <ProgressItem
        intl={intl}
        key={`progressItem-${i}`}
        title={p.title}
        subtitle={subtitle}
        status={getProgressStatus(i, status)}
        icon={p.icon}
      >
        {ApplicationProgress.renderBody(p.body)}
        {ApplicationProgress.renderList(p.list)}
      </ProgressItem>
    );
  };

  render() {
    const {
      intl: { formatMessage },
      isSpinnerLoading,
      isShared,
    } = this.props;

    return (
      <View inverseHeader>
        <Spinner loading={isSpinnerLoading}>
          <ContentsWrapper
            id='ApplicationProgress'
            title={formatMessage(messages.title)}
            animateHeading={false}
            inverseHeader
          >
            <ProgressSection>
              {progressItems.map(this.renderProgressItem)}
            </ProgressSection>
            {!isShared && (
              <ButtonNext url={DASHBOARD_BASE_PATH}>
                {formatMessage(commonMessages.next)}
              </ButtonNext>
            )}
          </ContentsWrapper>
        </Spinner>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  isSpinnerLoading: UISelectors.hasActiveSpinners(state),
  status: applicationSelectors.applicationStatus(state),
  isShared: applicationSelectors.isSharedApplication(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestLoanApplicationStatus:
        loanApplicationActions.requestLoanApplicationStatus,
    },
    dispatch,
  );

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ApplicationProgress),
);
