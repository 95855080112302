export const LOADING_FETCH_TRANSACTIONS = [
  { timer: 8000, message: 'Downloading Data' },
  { timer: 8000, message: 'Categorising Transactions' },
  { timer: 8000, message: 'Generating Statements' },
  { timer: 8000, message: 'Downloading Statements' },
  { timer: 8000, message: 'Saving to your profile' },
  { timer: 8000, message: 'Still Working', loopHere: true },
  { timer: 8000, message: 'Please Wait' },
];

export const LOADING_CONNECTING_BANKS = 'Connecting';
export const LOADING_SUBTITLE = 'Please wait. This could take a minute or two.';
export const LOADING_CAPTION = `We'll let you know if we encounter any problems. Don't refresh the page or hit back. Just relax and we'll make it happen.`;
