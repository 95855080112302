/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl } from 'react-intl';
import { push } from '@loan_market/react-router-redux-multi';

import productsActions from 'actions/productsActions';
import structureActions from 'actions/structureActions';

import * as productSelectors from 'selectors/productSelectors';
import * as UISelectors from 'selectors/UISelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';
import * as structureSelectors from 'selectors/structureSelectors';

import { COMPARE_PAGE_PATH } from 'shared/constants/paths';
import { fromLoanToApply } from 'lib/pathHelper';
import commonMessages from 'constants/commonMessages';

import View from 'components/View/View';
import Spinner from 'components/Spinner/Spinner';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import ProductDetailContent from 'components/ProductDetailContent/ProductDetailContent';
import Button from 'components/Button/Button';

export class ProductDetail extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    isLocked: PropTypes.bool.isRequired,
    isSpinnerLoading: PropTypes.bool,
    requestProductDetail: PropTypes.func.isRequired,
    clearProductDetail: PropTypes.func.isRequired,
    saveStructure: PropTypes.func.isRequired,
    selectProduct: PropTypes.func.isRequired,
    structure: PropTypes.object,
    productDetailMetaData: PropTypes.object,
    loanApplicationId: PropTypes.number,
    intl: intlShape.isRequired,
    goTo: PropTypes.func.isRequired,
    history: PropTypes.object,
  };

  componentDidMount() {
    const {
      requestProductDetail,
      match: {
        params: { productId },
      },
    } = this.props;

    requestProductDetail({ productId });

    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    const { clearProductDetail } = this.props;
    clearProductDetail();
  }

  onSelectProduct = (isSelectedProduct) => {
    const {
      selectProduct,
      saveStructure,
      productDetailMetaData: { product },
      loanApplicationId,
      structure,
      goTo,
    } = this.props;
    if (loanApplicationId) {
      if (isSelectedProduct) {
        goTo(COMPARE_PAGE_PATH);
      } else {
        saveStructure({
          structure: {
            ...structure,
            loanApplicationId,
            productId: product.id,
            lenderId: product.lender.id,
          },
          nextPath: fromLoanToApply(loanApplicationId),
        });
      }
    } else {
      selectProduct(product);
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      isLocked,
      isSpinnerLoading,
      productDetailMetaData,
      loanApplicationId,
      intl: { formatMessage },
      history,
    } = this.props;
    const {
      location: {
        query: { source },
      },
    } = history;

    return (
      <View>
        <Spinner loading={isSpinnerLoading}>
          <ContentsWrapper id='productDetail' animateHeading={false}>
            <Button
              id='backButton'
              icon='mi-arrow-left'
              theme='backButtonTheme'
              onClick={this.goBack}
            >
              {formatMessage(commonMessages.back)}
            </Button>
            {productDetailMetaData && productDetailMetaData.product.id && (
              <ProductDetailContent
                {...productDetailMetaData}
                onSelectProduct={this.onSelectProduct}
                source={source}
                isLocked={isLocked}
                isLoggedIn={!!loanApplicationId}
              />
            )}
          </ContentsWrapper>
        </Spinner>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  scenario: state.scenario,
  isSpinnerLoading: UISelectors.hasActiveSpinners(state),
  productDetailMetaData: productSelectors.productDetailMetaData(state),
  structure: structureSelectors.workingStructure(state),
  loanApplicationId: applicationSelectors.getApplicationId(state),
  isLocked: applicationSelectors.getIsLocked(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestProductDetail: productsActions.requestProductDetail,
      clearProductDetail: productsActions.clearProductDetail,
      selectProduct: structureActions.selectProduct,
      saveStructure: structureActions.saveStructure,
      goTo: push,
    },
    dispatch,
  );

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ProductDetail),
);
