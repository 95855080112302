/* eslint-disable react/jsx-curly-newline */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import renderHTML from 'react-render-html';
import { push } from '@loan_market/react-router-redux-multi';
import { withOktaAuth } from '@okta/okta-react';

import * as scenarioSelectors from 'selectors/scenarioSelectors';
import * as UISelectors from 'selectors/UISelectors';

import scenarioActions from 'actions/scenarioActions';

import Questions from 'lib/Questions';

import { intlMobile } from 'shared/lib/utils';
import { lowestRateDisclaimer } from 'lib/disclaimerHelper';
import { OWNER_OCCUPIED_RESIDENCE } from 'shared/constants/options';
import {
  REQUIRED,
  EMAIL,
  NO_ERROR,
  MOBILE_INTERNATIONAL,
  MOBILE,
} from 'constants/validators';
import { optimizeActivate } from 'lib/utils/browserUtils';
import { logger } from 'lib/coreLogger';
import { featureFlags } from 'lib/rollout';
import { unformatPhoneNumber } from 'lib/utils/numberUtils';

import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';
import findLocality from 'services/localitiesApi';

import UserUnisexSvg from 'assets/icons/user_unisex.svg';

import MyCRMLogin from 'components/MyCRMLogin/MyCRMLogin';
import Spinner from 'components/Spinner/Spinner';
import AutocompleteInput from 'components/AutocompleteInput/AutocompleteInput';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import Question from 'components/Question/Question';
import View from 'components/View/View';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import MobileInput from 'components/Input/MobileInput';
import MobileInputIntl from 'components/MobileInputIntl/MobileInputIntl';
import locale from 'config/locale';

const questionSet = (props) => {
  const questions = new Questions();
  const MOBILE_VALIDATION = featureFlags.internationalPhoneNumbers.isEnabled()
    ? MOBILE_INTERNATIONAL
    : MOBILE;
  questions.addBranch(
    ['displayName', REQUIRED],
    ['lastName', REQUIRED],
    ['email', REQUIRED, EMAIL],
    ['mobile', REQUIRED, MOBILE_VALIDATION],
  );
  props.showPostcodeField && questions.add(['postcode', REQUIRED]);
  questions.add('submitButton');
  return questions.arrayOfQuestions();
};

const messages = defineMessages({
  acknowledgeText: {
    id: 'ContactDetails.acknowledgeText',
    defaultMessage: `<p>You acknowledge and agree to your contact details being passed on to Loan Market Pty Ltd, so that a broker may contact you.</p>
                     <p>Our privacy policy contains information about how you may access or seek correction of the information we hold about you, how we manage that information and our complaints process.</p>
                     <p>You may opt out of any further contact or marketing communications at any time.</p>`,
  },
  continue: {
    id: 'ContactDetails.continue',
    defaultMessage: 'Continue',
  },
  title: {
    id: 'ContactDetails.title',
    defaultMessage: 'Searching 1000’s of home loan rates...',
  },
  titleApply: {
    id: 'ContactDetails.titleApply',
    defaultMessage:
      'Sign up to see today’s top deals and apply for pre-approval',
  },
  titleLowestRate: {
    id: 'ContactDetails.titleLowestRate',
    defaultMessage: `Unlock rates as low as {lowestRate}% p.a.*`,
  },
  tooglePopupText: {
    id: 'ContactDetails.tooglePopupText',
    defaultMessage: 'Sign up is free - How it works',
  },
  popupInfoContent: {
    id: 'ContactDetails.popupInfoContent',
    defaultMessage: `
        <h1>How you’ll save.</h1>
        <p>Having someone who gets it can really make a difference. We’ll negotiate hard on your behalf to secure discounted rates and fee reductions. Our buying power and experience means you get the best deal possible.</p>
        <h1>What we do.</h1>
        <p>We review your income and assets, figure out how much you can borrow, find the right loan for you from a wide range of choices, prepare all the paperwork and work with the lender to get your application approved. Brokers are excellent at this kind of thing - over half of all home loans in Australia are written by mortgage brokers.</p>
        <h1>How we get paid.</h1>
        <p>Our commission does not affect how much you pay for your loan. It’s the same interest rates and fees if you go direct to the bank, or work with us.</p>
        <h1>Your privacy is important.</h1>
        <p>We take care of your personal details and you can opt out of communications at any time.</p>`,
  },
  postcode: {
    id: 'ContactDetails.Postcode',
    defaultMessage: 'Current Postcode',
  },
  suburb: {
    id: 'ContactDetails.suburb',
    defaultMessage: 'Suburb',
  },
  postcodeAU: {
    id: 'ContactDetails.postcodeAU',
    defaultMessage: `Where in Australia would you like us to find your broker?`,
  },
  postcodeNZ: {
    id: 'ContactDetails.postcodeNZ',
    defaultMessage: `Where in New Zealand would you like us to find your broker?`,
  },
  yourName: {
    id: 'ContactDetails.yourName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'ContactDetails.lastName',
    defaultMessage: 'Last Name',
  },
  emailAddress: {
    id: 'ContactDetails.emailAddress',
    defaultMessage: 'Email Address',
  },
  mobile: {
    id: 'ContactDetails.mobile',
    defaultMessage: 'Mobile Number',
  },
});

export class ContactDetails extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    scenario: PropTypes.object.isRequired,
    ...manageQuestionsPropTypes,
    isSpinnerLoading: PropTypes.bool,
    showPostcodeField: PropTypes.bool,
    setPostcodeManualEntry: PropTypes.func.isRequired,
    setEmail: PropTypes.func.isRequired,
    setMobile: PropTypes.func.isRequired,
    setDisplayName: PropTypes.func.isRequired,
    setLastName: PropTypes.func.isRequired,
    goTo: PropTypes.func.isRequired,
    defaultExistingProperty: PropTypes.object,
    prospectiveProperty: PropTypes.object,
    requestActivateOrganicSignup: PropTypes.func,
    fullPageSpinnerLoading: PropTypes.bool,
    oktaAuth: PropTypes.object,
  };

  static questionsToAsk = questionSet;

  static revealMethod = 'steps';
  static revealOverBranch = false;

  constructor(props) {
    super(props);
    this.logger = logger('ContactDetails');
  }

  componentDidMount() {
    const { scenario } = this.props;
    window.scrollTo(0, 0);
    optimizeActivate('foo', 'on');
    this.logger.info({
      action: 'on contact details form',
      data: { scenario },
    });
  }

  setEmail = (value) => {
    this.props.setEmail(value);
    this.removeAsyncError();
  };

  removeAsyncError() {
    this.props.setError({
      id: 'email',
      text: NO_ERROR,
    });
  }

  handleSubmit = () => {
    const { requestSignUp, scenario } = this.props;
    this.logger.info({
      action: 'submit contact details form',
      data: { scenario },
    });
    requestSignUp();
    optimizeActivate('foo', 'off');
  };

  renderTerms = () => {
    return (
      <div>
        <div className='terms'>
          We promise to never share your personal details with anyone.
        </div>

        <div className='terms'>
          By submitting this form, you accept Loan Market’s{' '}
          <a
            href={`${locale.data.website}/terms-and-conditions`}
            target='_blank'
            rel='noopener noreferrer'
          >
            Terms
          </a>{' '}
          and{' '}
          <a
            href={`${locale.data.website}/privacy-policy`}
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy Policy
          </a>
        </div>
      </div>
    );
  };

  renderAcknowledgmentAndDisclaimer() {
    const {
      intl: { formatMessage },
      lowestRate,
      prospectiveProperty,
      defaultExistingProperty: existingProperty,
      propertyPurpose,
    } = this.props;
    const property = prospectiveProperty || existingProperty || {};
    const fineprint = [renderHTML(formatMessage(messages.acknowledgeText))];
    if (lowestRate) {
      fineprint.push(
        lowestRateDisclaimer(
          lowestRate,
          propertyPurpose(property.id) === OWNER_OCCUPIED_RESIDENCE,
        ),
      );
    }
    return fineprint;
  }

  handlePasswordSubmit = () => {
    const { requestActivateOrganicSignup, oktaAuth } = this.props;
    requestActivateOrganicSignup(oktaAuth);
  };

  onMobileChange = (num) => this.props.setMobile(unformatPhoneNumber(num));

  render() {
    const {
      scenario,
      isSpinnerLoading,
      setPostcodeManualEntry,
      setDisplayName,
      setLastName,
      setCurrentQuestionTo,
      setError,
      setMobile,
      formCompleted,
      questions,
      lowestRate,
      isPostcodeCountryAUOrNZ,
      fullPageSpinnerLoading,
      errors = {},
      intl: { formatMessage },
    } = this.props;
    const { countryCode, dialCode } = scenario.mobile;
    let postcodeMessage = locale.isAU ? 'postcode' : 'suburb';
    if (!isPostcodeCountryAUOrNZ) {
      postcodeMessage = locale.isAU ? 'postcodeAU' : 'postcodeNZ';
    }

    if (scenario.oktaData?.status === 'PASSWORD_RESET') {
      return (
        <MyCRMLogin
          headingTitle='Choose a password to secure your profile'
          handleSubmit={this.handlePasswordSubmit}
          centeredTitle
          showFooter
          stateToken={scenario.oktaData.stateToken}
          fullPageSpinnerLoading={fullPageSpinnerLoading}
        />
      );
    }

    return (
      <View
        footerDisclaimer={this.renderAcknowledgmentAndDisclaimer()}
        hideStandardDisclaimer
        inverse
      >
        <Spinner loading={isSpinnerLoading}>
          <ContentsWrapper
            id='contactDetails'
            title='Create your profile'
            subtitles={
              lowestRate
                ? [formatMessage(messages.titleLowestRate, { lowestRate })]
                : undefined
            }
            headingIcon={UserUnisexSvg}
            extraHint={this.renderTerms}
            isForm
            onSubmit={this.handleSubmit}
            formCompleted={formCompleted}
            className='halfwidth'
            inverseHeader
            onFocusLost={setCurrentQuestionTo(undefined)}
          >
            <Question
              {...questions.displayName}
              label={formatMessage(messages.yourName)}
              className='fullWidthInput'
            >
              <Input action={setDisplayName} value={scenario.displayName} />
            </Question>

            <Question
              {...questions.lastName}
              label={formatMessage(messages.lastName)}
              className='fullWidthInput'
            >
              <Input action={setLastName} value={scenario.lastName} />
            </Question>

            <Question
              {...questions.email}
              label={formatMessage(messages.emailAddress)}
              className='fullWidthInput'
              showError
              error={errors.email && errors.email.text}
            >
              <Input
                type='email'
                action={this.setEmail}
                value={scenario.email}
              />
            </Question>

            <Question
              {...questions.mobile}
              label={formatMessage(messages.mobile)}
              className='fullWidthInput'
              showError
              error={errors.mobile && errors.mobile.text}
            >
              {featureFlags.internationalPhoneNumbers.isEnabled() ? (
                <MobileInputIntl
                  onChange={setMobile}
                  value={intlMobile(scenario.mobile)}
                  countryCode={countryCode}
                  dialCode={dialCode}
                  isMobile
                />
              ) : (
                <MobileInput
                  action={this.onMobileChange}
                  value={intlMobile(scenario.mobile)}
                />
              )}
            </Question>

            <Question
              {...questions.postcode}
              label={formatMessage(messages[postcodeMessage])}
              className='fullWidthInput'
            >
              <AutocompleteInput
                setAsyncError={(text) =>
                  setError({ id: 'postcode', text, blocking: true })
                }
                removeAsyncError={() =>
                  setError({ id: 'postcode', text: NO_ERROR })
                }
                action={setPostcodeManualEntry}
                value={
                  scenario.postcode ? scenario.postcode.toString() : undefined
                }
                serviceFn={findLocality}
              />
            </Question>

            <Button
              {...questions.submitButton}
              disabled={!formCompleted}
              onClick={this.handleSubmit}
              theme='transparent'
              className='brandColor__font--hover'
            >
              Next
            </Button>
          </ContentsWrapper>
        </Spinner>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  scenario: state.scenario,
  errors: state.scenario.errors,
  prospectiveProperty: scenarioSelectors.prospectiveProperty(state),
  defaultExistingProperty: scenarioSelectors.defaultExistingProperty(state),
  propertyPurpose: scenarioSelectors.propertyPurpose(state),
  lowestRate: scenarioSelectors.lowestRate(state),
  isSpinnerLoading: UISelectors.hasActiveSpinners(state),
  isPostcodeCountryAUOrNZ: scenarioSelectors.isPostcodeCountryAUOrNZ(state),
  showPostcodeField: !scenarioSelectors.isAllocatedToBroker(state),
  fullPageSpinnerLoading: state.UISettings.fullPageSpinnerLoading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPostcodeManualEntry: scenarioActions.setPostcodeManualEntry,
      requestSignUp: scenarioActions.requestSignUp,
      setEmail: scenarioActions.setEmail,
      setMobile: scenarioActions.setMobile,
      setError: scenarioActions.setError,
      setDisplayName: scenarioActions.setDisplayName,
      setLastName: scenarioActions.setLastName,
      requestActivateOrganicSignup:
        scenarioActions.requestActivateOrganicSignup,
      goTo: push,
    },
    dispatch,
  );

export default withOktaAuth(
  injectIntl(
    compose(connect(mapStateToProps, mapDispatchToProps), manageQuestions)(
      ContactDetails,
      (props) => ({
        ...props.scenario,
        showPostcodeField: props.showPostcodeField,
      }),
    ),
  ),
);
