import {
  STATUS_ESTABLISHED,
  STATUS_NEW_BUILDING,
  STATUS_VACANT_LAND,
} from 'shared/constants/myCRMTypes/property';

export default [
  {
    value: STATUS_ESTABLISHED.name,
    label: STATUS_ESTABLISHED.name,
    icon: 'sl-custom-house-2',
  },
  {
    value: STATUS_NEW_BUILDING.name,
    label: STATUS_NEW_BUILDING.name,
    icon: 'sl-custom-driller',
  },
  {
    value: STATUS_VACANT_LAND.name,
    label: STATUS_VACANT_LAND.name,
    icon: 'sl-custom-anchor-point-1',
  },
];
