import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import bowser from 'bowser';

import View from 'components/View/View';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import Spinner from 'components/Spinner/Spinner';
import SpinnerText from 'components/SpinnerText/SpinnerText';
import Iframe from 'components/Iframe/Iframe';
import Button from 'components/Button/Button';
import BankList from 'components/BankList/BankList';
import { IFRAME_STATUS_COMPLETE } from 'containers/BankConnectStatus';

import mogoConnectActions from 'actions/mogoConnectActions';
import UIActions from 'actions/UIActions';
import loanApplicationActions from 'actions/loanApplicationActions';

import * as mogoConnectSelectors from 'selectors/mogoConnectSelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';
import * as UISelectors from 'selectors/UISelectors';
import * as documentSelectors from 'selectors/documentSelectors';

import commonMessages from 'constants/commonMessages';
import { afterExpenseBankConnectFetched } from 'lib/pathHelper';
import {
  LOADING_FETCH_TRANSACTIONS,
  LOADING_CONNECTING_BANKS,
  LOADING_SUBTITLE,
  LOADING_CAPTION,
} from 'constants/loadingMessages';

const IFRAME_HEIGHT_WEB = 640;
const IFRAME_HEIGHT_MOBILE = 620;

export const messages = defineMessages({
  whichBank: {
    id: 'BankConnect.whichBank',
    defaultMessage: 'Which bank would you like to access?',
  },
  connecting: {
    id: 'BankConnect.connecting',
    defaultMessage: 'Connecting with your bank',
  },
});

const getIframeHeight = () => {
  return bowser.mobile ? IFRAME_HEIGHT_MOBILE : IFRAME_HEIGHT_WEB;
};

export class BankConnect extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    requestBankList: PropTypes.func.isRequired,
    readLoanApplication: PropTypes.func.isRequired,
    bankList: PropTypes.shape({
      bigFour: PropTypes.array.isRequired,
      others: PropTypes.array.isRequired,
    }).isRequired,
    numberOfBanks: PropTypes.number.isRequired,
    bankName: PropTypes.string,
    loginUrl: PropTypes.string,
    isFetchingLogin: PropTypes.bool.isRequired,
    requestBankLogin: PropTypes.func.isRequired,
    setBankDetails: PropTypes.func.isRequired,
    checkBankLoginComplete: PropTypes.func.isRequired,
    clearBankConnectSession: PropTypes.func.isRequired,
    accessId: PropTypes.string,
    applicationId: PropTypes.number,
    goToPathWithAnimation: PropTypes.func.isRequired,
    intl: intlShape,
    isSpinnerLoading: PropTypes.bool,
    isAccessIdChecked: PropTypes.bool,
    documents: PropTypes.arrayOf(PropTypes.object),
  };

  constructor(props) {
    const { location } = props.history;
    super(props);
    this.state = {
      isIFrameLoading: false,
      fetchingCollection: false,
    };
    this.fullReport = ['true', undefined].includes(location.query.fullReport);
    this.nextPath = this.fullReport
      ? `/apply/${props.applicationId}/expenses`
      : `/apply/${props.applicationId}/documents`;
  }

  componentDidMount() {
    const { requestBankList, readLoanApplication } = this.props;
    requestBankList();
    /* all we need is primary contact id in order to create financials later
      but might as well read the whole app cause it's more complex than it seems... */
    readLoanApplication({ partialBackgroundLoad: true });
    window.scrollTo(0, 0);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.loginUrl && nextProps.loginUrl !== this.props.loginUrl) {
      this.setIframeLoading(true);
    }
    if (
      nextProps.isAccessIdChecked &&
      this.props.documents.length !== nextProps.documents.length
    ) {
      this.onBankStatementsFetched();
    }
  }

  setIframeLoading = (isIFrameLoading) => {
    this.setState({
      isIFrameLoading,
    });
  };

  handleIframeLoaded = () => {
    this.setIframeLoading(false);
  };

  showSpinner = () => {
    this.setState({
      fetchingCollection: true,
    });
  };

  completeFetchingBankStatements = () => {
    this.setState({
      fetchingCollection: false,
    });
  };

  clearIFrame = () => {
    const { clearBankConnectSession } = this.props;

    clearBankConnectSession();
    this.completeFetchingBankStatements();
    window.scrollTo(0, 0);
  };

  nextStep = () => {
    const { accessId, checkBankLoginComplete } = this.props;

    checkBankLoginComplete({ accessId, fullReport: this.fullReport });
    window.scrollTo(0, 0);
    this.showSpinner();
  };

  handleIframeMessage = (message) => {
    const { accessId } = this.props;
    if (message.accessId === accessId) {
      if (message.status === IFRAME_STATUS_COMPLETE) {
        this.nextStep();
      } else {
        this.clearIFrame();
      }
    }
  };

  onBankStatementsFetched = () => {
    const {
      goToPathWithAnimation,
      clearBankConnectSession,
      bankName,
    } = this.props;

    goToPathWithAnimation(
      afterExpenseBankConnectFetched({
        path: this.nextPath,
        isFullReport: this.fullReport,
        bankName,
      }),
    );

    setTimeout(this.completeFetchingBankStatements, 100);
    setTimeout(clearBankConnectSession, 100);
  };

  renderLoginContent() {
    const { loginUrl } = this.props;

    return (
      <div>
        <Iframe
          src={loginUrl}
          frameBorder='0'
          height={getIframeHeight()}
          id='mogo'
          useObject={false}
          useSpinner={false}
          onLoad={this.handleIframeLoaded}
          handleIframeMessage={this.handleIframeMessage}
        />
      </div>
    );
  }

  renderIframe() {
    const {
      intl: { formatMessage },
      isFetchingLogin,
      loginUrl,
    } = this.props;
    const { fetchingCollection, isIFrameLoading } = this.state;

    const titleProp = fetchingCollection
      ? { titleSequence: LOADING_FETCH_TRANSACTIONS }
      : { title: LOADING_CONNECTING_BANKS };

    const spinnerProps = {
      ...titleProp,
      subtitle: LOADING_SUBTITLE,
      caption: LOADING_CAPTION,
    };

    const spinnerContent = <SpinnerText {...spinnerProps} />;

    return (
      <>
        <Button
          id='backButton'
          icon='mi-arrow-left'
          theme='backButtonTheme'
          onClick={this.clearIFrame}
        >
          {formatMessage(commonMessages.back)}
        </Button>
        <Spinner
          loading={fetchingCollection || isIFrameLoading || isFetchingLogin}
          fillParent
          content={spinnerContent}
        >
          {fetchingCollection || !loginUrl ? (
            <div style={{ height: getIframeHeight() }} />
          ) : (
            this.renderLoginContent()
          )}
        </Spinner>
      </>
    );
  }

  isIframeShown() {
    const { loginUrl, isFetchingLogin } = this.props;
    const { fetchingCollection } = this.state;

    return loginUrl || isFetchingLogin || fetchingCollection;
  }

  goBack = () => {
    const { clearBankConnectSession, goToPathWithAnimation } = this.props;

    clearBankConnectSession();
    goToPathWithAnimation({ path: this.nextPath, animation: ['/ok'] });
  };

  renderContent() {
    const {
      numberOfBanks,
      bankList,
      requestBankLogin,
      setBankDetails,
    } = this.props;

    if (this.isIframeShown()) {
      window.scrollTo(0, 0);
      return this.renderIframe();
    }

    if (numberOfBanks > 0 && !this.state.fetchingCollection) {
      return (
        <BankList
          onSkip={this.goBack}
          bankList={bankList}
          requestBankLogin={requestBankLogin}
          setBankDetails={setBankDetails}
        />
      );
    }
  }

  render() {
    const {
      intl: { formatMessage },
    } = this.props;

    const showHeader = !this.isIframeShown();

    return (
      <View showHeader={showHeader}>
        <meta name='robots' content='noindex, nofollow' />
        <Spinner loading={this.props.isSpinnerLoading}>
          <ContentsWrapper
            id='bankConnect'
            title={showHeader ? formatMessage(messages.whichBank) : ''}
          >
            {this.renderContent()}
          </ContentsWrapper>
        </Spinner>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  bankList: mogoConnectSelectors.bankList(state),
  numberOfBanks: mogoConnectSelectors.numberOfLenders(state),
  loginUrl: mogoConnectSelectors.loginUrl(state),
  isFetchingLogin: mogoConnectSelectors.isLoginFetching(state),
  accessId: mogoConnectSelectors.loginAccessId(state),
  isAccessIdChecked: mogoConnectSelectors.isAccessIdChecked(state),
  bankName: mogoConnectSelectors.bankName(state),
  isSpinnerLoading: UISelectors.hasActiveSpinners(state),
  pageError: state.UISettings.pageError,
  applicationId: applicationSelectors.getApplicationId(state),
  documents: documentSelectors.entities(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      requestBankList: mogoConnectActions.requestBankList,
      requestBankLogin: mogoConnectActions.requestBankLogin,
      clearBankConnectSession: mogoConnectActions.clearBankConnectSession,
      checkBankLoginComplete: mogoConnectActions.checkBankLoginComplete,
      goToPathWithAnimation: UIActions.goToPathWithAnimation,
      readLoanApplication: loanApplicationActions.readLoanApplication,
      setBankDetails: mogoConnectActions.setBankDetails,
    },
    dispatch,
  );
};

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(BankConnect);
