import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

import scenarioActions from 'actions/scenarioActions';
import UIActions from 'actions/UIActions';

import { CASH_FLOW_REPORT_PATH } from 'shared/constants/paths';
import { PROSPECTIVE_PROPERTY_ID } from 'shared/constants/defaults';
import { REQUIRED } from 'constants/validators';
import { FREQUENCY_OPTIONS } from 'constants/options';
import { WEEKLY } from 'shared/constants/options';

import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';
import sanityCheck from 'hocs/sanityCheck';

import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import Question from 'components/Question/Question';
import View from 'components/View/View';
import ButtonNext from 'components/ButtonNext/ButtonNext';
import CurrencyInputWithSelection from 'components/CurrencyInputWithSelection/CurrencyInputWithSelection';

import { getProspectiveProperty } from 'shared/lib/propertyHelper';

const messages = defineMessages({
  title: {
    id: 'PropertyRent.title',
    defaultMessage: 'What do you expect to receive in rent?',
  },
  rentalAmountQuestion: {
    id: 'BankingPreferences.rentalAmountQuestion',
    defaultMessage: 'How much do you think you could rent it out for?',
  },
});

class PropertyRent extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    scenario: PropTypes.object.isRequired,
    setPropertyRentalAmount: PropTypes.func.isRequired,
    setPropertyRentalFrequency: PropTypes.func.isRequired,
    requestCashFlowData: PropTypes.func.isRequired,
    setNextPath: PropTypes.func.isRequired,
    startAnimation: PropTypes.func.isRequired,
    ...manageQuestionsPropTypes,
  };

  static isRequiredStructure = (props) =>
    !!getProspectiveProperty(props.scenario);

  static questionsToAsk = () => [
    { id: 'rentalAmount', branch: 1, validation: [REQUIRED] },
  ];

  static revealMethod = 'steps';
  static revealOverBranch = false;

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSubmit = () => {
    const {
      requestCashFlowData,
      setNextPath,
      startAnimation,
      scenario,
    } = this.props;
    setNextPath(CASH_FLOW_REPORT_PATH);
    startAnimation(['/great', '/investment-cashflow']);
    requestCashFlowData({
      scenario,
      propertyId: PROSPECTIVE_PROPERTY_ID,
    });
  };

  render() {
    const {
      scenario,
      intl,
      setPropertyRentalAmount,
      setPropertyRentalFrequency,
      questions,
      setCurrentQuestionTo,
      formCompleted,
    } = this.props;
    const prospectiveProperty = getProspectiveProperty(scenario);

    return (
      <View>
        <ContentsWrapper
          id='propertyRent'
          title={intl.formatMessage(messages.title)}
          isForm
          formCompleted={formCompleted}
          onSubmit={this.handleSubmit}
          onFocusLost={setCurrentQuestionTo(undefined)}
        >
          <Question
            {...questions.rentalAmount}
            label={intl.formatMessage(messages.rentalAmountQuestion)}
          >
            <CurrencyInputWithSelection
              action={setPropertyRentalAmount}
              selectionAction={setPropertyRentalFrequency}
              value={prospectiveProperty.rentalAmount}
              selectionValue={prospectiveProperty.rentalFrequency}
              selectionDefault={WEEKLY}
              selectionItems={FREQUENCY_OPTIONS}
            />
          </Question>
          <ButtonNext
            id='next'
            disabled={!formCompleted}
            onClick={this.handleSubmit}
          />
        </ContentsWrapper>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  scenario: state.scenario,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPropertyRentalAmount: scenarioActions.setPropertyRentalAmount(
        PROSPECTIVE_PROPERTY_ID,
      ),
      setPropertyRentalFrequency: scenarioActions.setPropertyRentalFrequency(
        PROSPECTIVE_PROPERTY_ID,
      ),
      requestCashFlowData: scenarioActions.requestCashFlowData,
      setNextPath: UIActions.setNextPath,
      startAnimation: UIActions.startAnimationSequence,
    },
    dispatch,
  );

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  sanityCheck,
  manageQuestions,
)(PropertyRent, (props) => ({ ...getProspectiveProperty(props.scenario) }));
