import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import Check from 'assets/icons/Check';

import { PROGRESS_STATUS_COMPLETE } from 'constants/applicationProgress';

import styles from './ProgressSection.css';

class ProgressSection extends Component {
  static propTypes = {
    children: PropTypes.arrayOf(PropTypes.node),
  };

  constructor(props) {
    super(props);
    this.progressItemRefs = [];
    this.state = {
      progressItemData: [],
    };
  }

  componentDidMount() {
    this.constructTimeline();
    window.addEventListener('resize', this.constructTimeline);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    this.constructTimeline();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.constructTimeline);
  }

  constructTimeline = () => {
    const containerRect = this.container.getBoundingClientRect();
    const containerTop = containerRect.top;

    const progressItemData = this.progressItemRefs.map(({ ref, status }) => {
      const rect = ref.getBoundingClientRect();
      return {
        top: rect.top + 25 - containerTop,
        status,
      };
    });
    setTimeout(() => this.setState({ progressItemData }), 0);
  };

  renderProgressItem = (child, index) => {
    return (
      <div
        key={`progress-item-${index}`}
        ref={(ref) => {
          this.progressItemRefs[index] = {
            ref,
            status: child.props.status,
          };
        }}
      >
        {child}
      </div>
    );
  };

  renderLine = () => {
    const { progressItemData } = this.state;
    if (progressItemData.length < 2) {
      return null;
    }
    return (
      <div
        className={styles.line}
        style={{
          top: `${progressItemData[0].top}px`,
          height: `${
            progressItemData[progressItemData.length - 1].top -
            progressItemData[0].top
          }px`,
        }}
      />
    );
  };

  renderPoints = () =>
    this.state.progressItemData.map((item, index) => (
      <div
        key={`progress-point-${index}`}
        className={classNames(styles.point, styles[item.status])}
        style={{ top: `${item.top}px` }}
      >
        {item.status === PROGRESS_STATUS_COMPLETE ? (
          <Check className={styles.check} />
        ) : (
          <div />
        )}
      </div>
    ));

  renderTips = () =>
    this.state.progressItemData.map((item, index) => (
      <div
        key={`progress-tip-${index}`}
        className={classNames(styles.tip, styles[item.status])}
        style={{ top: `${item.top}px` }}
      />
    ));

  render() {
    const { children } = this.props;
    const progressItems = _.flatten(children).map(this.renderProgressItem);
    return (
      <div
        className={styles.root}
        ref={(ref) => {
          this.container = ref;
        }}
      >
        {this.renderLine()}
        {progressItems}
        {this.renderTips()}
        {this.renderPoints()}
      </div>
    );
  }
}

export default ProgressSection;
