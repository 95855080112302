import React from 'react';

import { ModalProvider } from 'contexts/ModalContext';
import { ParentWrapper } from 'containers/goal/ParentWrapper';

import GoalTeam from './GoalTeam';

const GoalTeamApplyWrapper = () => {
  return (
    <ModalProvider>
      <ParentWrapper>
        <GoalTeam />
      </ParentWrapper>
    </ModalProvider>
  );
};

export default GoalTeamApplyWrapper;
