import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import scenarioActions from 'actions/scenarioActions';
import UIActions from 'actions/UIActions';

import { cashFlowCalculator } from 'lib/cashFlowCalculator';

import {
  NEGATIVE_CHART_SPECTRUM,
  POSITIVE_CHART_SPECTRUM,
  SUMMARY_SECTION_COLOUR,
} from 'constants/colours';

import ReportContent from 'components/ReportContent/ReportContent';

import * as scenarioSelectors from 'selectors/scenarioSelectors';
import { AU_UNIQUE_ITEM } from 'constants/reportItemTitles';

import { rentCollectedMetadata } from 'constants/reportMetadata';
import {
  hydrateMetadata,
  cashFlowReportCostSection,
} from 'selectors/reportSelectors';

import locale from 'config/locale';

class CashFlowReportContent extends Component {
  static propTypes = {
    property: PropTypes.object.isRequired,
    rentCollectedSection: PropTypes.object.isRequired,
    investmentExpensesSection: PropTypes.arrayOf(PropTypes.object.isRequired),
    loanAmount: PropTypes.number,
    isOpenByDefault: PropTypes.bool.isRequired,
    mergePropertyReportData: PropTypes.func.isRequired,
    children: PropTypes.node,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const thisProperty = this.props.property;
    const nextProperty = nextProps.property;
    const { mergePropertyReportData } = nextProps;
    // BEWARE! THIS IS ATTROCIOUS CODE!
    // This need to be refactored to use selectors
    // rather than what ever this craziness is.
    if (!_.isEqual(thisProperty, nextProperty)) {
      mergePropertyReportData(
        cashFlowCalculator(
          nextProperty,
          nextProperty.mortgageAmount || nextProps.loanAmount,
        ),
      );
    }
    if (this.props.loanAmount !== nextProps.loanAmount) {
      mergePropertyReportData(
        cashFlowCalculator(nextProperty, nextProps.loanAmount),
      );
    }
  }

  render() {
    const {
      property,
      rentCollectedSection,
      investmentExpensesSection,
      isOpenByDefault,
    } = this.props;
    const isNegativeNews = property.totalCashFlow < 0;

    if (!locale.isAU) {
      _.remove(investmentExpensesSection, (p) =>
        AU_UNIQUE_ITEM.includes(p.name),
      );
    }

    const data = [
      {
        label: 'Investment Income',
        shortLabel: 'Income',
        isChartTotal: !isNegativeNews,
        colour: !isNegativeNews ? SUMMARY_SECTION_COLOUR : null,
        isOpenByDefault,
        isAccordion: false,
        items: [rentCollectedSection],
      },
      {
        label: 'Investment Expenses',
        shortLabel: 'Expenses',
        isChartTotal: !isNegativeNews,
        isOpenByDefault,
        isAccordion: false,
        items: investmentExpensesSection,
      },
      {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        label: isNegativeNews ? 'Negative Cashflow' : 'Investment Cashflow',
        shortLabel: isNegativeNews ? 'Negative Cashflow' : 'Positive Cashflow',
        isOpenByDefault,
        isAccordion: false,
        colour: isNegativeNews
          ? SUMMARY_SECTION_COLOUR
          : POSITIVE_CHART_SPECTRUM[0],
        items: [
          {
            name: isNegativeNews ? 'Negative Cashflow' : 'Investment Cashflow',
            icon: 'sl-custom-dollar-currency-3',
            value: Math.abs(property.totalCashFlow),
            spanValue: 'per week',
            readOnly: true,
          },
        ],
      },
    ];

    return (
      <ReportContent
        key='Cashflow'
        data={data}
        frequency={52}
        spectrum={(isNegativeNews
          ? NEGATIVE_CHART_SPECTRUM
          : POSITIVE_CHART_SPECTRUM
        ).slice(1)}
        theme='borderReport'
      >
        {this.props.children}
      </ReportContent>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  rentCollectedSection: hydrateMetadata(rentCollectedMetadata)(
    state,
    ownProps.property.id,
  ),
  investmentExpensesSection: cashFlowReportCostSection(
    state,
    ownProps.property.id,
  ),
  loanAmount: scenarioSelectors.loanAmount(state),
});

const mapDispatchToProps = (dispatch, ownProps) =>
  bindActionCreators(
    {
      mergePropertyReportData: scenarioActions.mergePropertyReportData(
        ownProps.property.id,
      ),
      setNextPath: UIActions.setNextPath,
      startAnimation: UIActions.startAnimationSequence,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CashFlowReportContent);
