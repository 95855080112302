import { defineMessages } from 'react-intl';

import {
  LOAN_PURPOSE_RESIDENTIAL,
  LOAN_PURPOSE_INVESTMENT,
  LOAN_PURPOSE_REFINANCE,
} from 'shared/constants/loanPurposes';
import {
  BUYER_SCENARIOS_PATH,
  ABOUT_PROPERTY_PATH,
  REASON_FOR_REFINANCE_PATH,
} from 'shared/constants/paths';

const messages = defineMessages({
  residentialLabel: {
    id: 'loanPurposes.residential.label',
    defaultMessage: 'Buy a home or move',
  },
  investmentLabel: {
    id: 'loanPurposes.investment.label',
    defaultMessage: 'Buy an investment property',
  },
  refinanceLabel: {
    id: 'loanPurposes.refinance.label',
    defaultMessage: 'Refinance or renovate',
  },
});

const nextPathMap = {
  [LOAN_PURPOSE_RESIDENTIAL]: `${BUYER_SCENARIOS_PATH}?loan_purpose=${LOAN_PURPOSE_RESIDENTIAL}`,
  [LOAN_PURPOSE_INVESTMENT]: ABOUT_PROPERTY_PATH,
  [LOAN_PURPOSE_REFINANCE]: `${REASON_FOR_REFINANCE_PATH}?loan_purpose=${LOAN_PURPOSE_REFINANCE}`,
};

const loanPurposes = (formatMessage) => () => [
  {
    key: LOAN_PURPOSE_RESIDENTIAL,
    value: LOAN_PURPOSE_RESIDENTIAL,
    icon: 'sl-custom-house-4',
    sprite: 'small-house',
    url: nextPathMap[LOAN_PURPOSE_RESIDENTIAL],
    label: formatMessage(messages.residentialLabel),
  },
  {
    key: LOAN_PURPOSE_INVESTMENT,
    value: LOAN_PURPOSE_INVESTMENT,
    icon: 'sl-custom-building-2',
    sprite: 'small-building',
    url: nextPathMap[LOAN_PURPOSE_INVESTMENT],
    label: formatMessage(messages.investmentLabel),
  },
  {
    key: LOAN_PURPOSE_REFINANCE,
    value: LOAN_PURPOSE_REFINANCE,
    icon: 'sl-custom-hand-coin',
    sprite: 'small-handcoins',
    label: formatMessage(messages.refinanceLabel),
    url: nextPathMap[LOAN_PURPOSE_REFINANCE],
  },
];

export default loanPurposes;
