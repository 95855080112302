import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { bindActionCreators } from 'redux';
import { push } from '@loan_market/react-router-redux-multi';
import locale from 'config/locale';

import View from 'components/View/View';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import Spinner from 'components/Spinner/Spinner';
import ApplySummary from 'components/ApplySummary/ApplySummary';
import ApplyBrokerCard from 'components/ApplyBrokerCard/ApplyBrokerCard';

import UIActions from 'actions/UIActions';
import otherActions from 'actions/otherActions';
import loanApplicationActions from 'actions/loanApplicationActions';
import structureActions from 'actions/structureActions';

import * as clientSelectors from 'selectors/clientSelectors';
import * as completionSelectors from 'selectors/completionSelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';
import * as UISelectors from 'selectors/UISelectors';
import * as structureSelectors from 'selectors/structureSelectors';
import * as fundingSelectors from 'selectors/fundingSelectors';

import { DEFAULT_STRUCTURE_INITIAL_STATE } from 'shared/constants/defaults';
import { structureToQueryString } from 'lib/compareHelper';
import { lenderLogoUrl } from 'lib/utils/imageUtils';
import { formatProductName } from 'lib/utils/stringUtils';
import LocalStorageProxy from 'lib/localStorageProxy';

import {
  APPLY_BASE_PATH,
  COMPARE_PAGE_PATH,
  APPLICATION_PROGRESS_BASE_PATH,
  PRODUCT_DETAIL_PATH,
  BORROWING_CAPACITY_PATH,
  INTEREST_SAVING_PATH,
} from 'shared/constants/paths';

export const messages = defineMessages({
  whereToNow: {
    id: 'Dashboard.whereToNow',
    defaultMessage: 'Where to now?',
  },
  myDashboard: {
    id: 'Dashboard.myDashboard',
    defaultMessage: 'My Dashboard',
  },
  completeProfile: {
    id: 'Dashboard.completeProfile',
    defaultMessage: 'Complete your profile',
  },
  viewProfile: {
    id: 'Dashboard.viewProfile',
    defaultMessage: 'View your profile',
  },
  completeProfileDescription: {
    id: 'Dashboard.completeProfileDescription',
    defaultMessage: '{percentageCompleted, number, percent} complete',
  },
  sendToBroker: {
    id: 'Dashboard.sendToBroker',
    defaultMessage: 'Send to your broker',
  },
  testPreapproval: {
    id: 'Dashboard.testPreapproval',
    defaultMessage: 'Test for pre-approval',
  },
  testPreapprovalDescription: {
    id: 'Dashboard.testPreapprovalDescription',
    defaultMessage: 'Understand your options',
  },
  borrowingCapacity: {
    id: 'Dashboard.borrowingCapacity',
    defaultMessage: 'View borrowing capacity',
  },
  borrowingCapacityDescription: {
    id: 'Dashboard.borrowingCapacityDescription',
    defaultMessage: 'See how much you can borrow',
  },
  borrowingCapacityDescriptionDisabled: {
    id: 'Dashboard.borrowingCapacityDescriptionDisabled',
    defaultMessage: 'To see how much you can borrow, complete your profile',
  },
  compareLoans: {
    id: 'Dashboard.compareLoans',
    defaultMessage: 'Compare home loans',
  },
  compareLoansDescription: {
    id: 'Dashboard.compareLoansDescription',
    defaultMessage: 'Find the right loan for you',
  },
  understandNext: {
    id: 'Dashboard.understandNext',
    defaultMessage: 'Understand what’s next',
  },
  understandNextDescription: {
    id: 'Dashboard.understandNextDescription',
    defaultMessage: 'The steps ahead',
  },
  reviewYourLoan: {
    id: 'Dashboard.reviewYourLoan',
    defaultMessage: 'Review your selected loan',
  },
  selectLoan: {
    id: 'Dashboard.selectLoan',
    defaultMessage: 'Select a loan',
  },
  shortlistLoan: {
    id: 'Dashboard.shortlistLoan',
    defaultMessage: 'Shortlist a loan',
  },
  interestSavings: {
    id: 'Dashboard.interestSavings',
    defaultMessage: 'View interest savings',
  },
  interestSavingsDescription: {
    id: 'Dashboard.interestSavingsDescription',
    defaultMessage: 'See how much you could save',
  },
  yourProfile: {
    id: 'Dashboard.yourProfile',
    defaultMessage: 'Your Profile',
  },
  loans: {
    id: 'Dashboard.loans',
    defaultMessage: 'Loans',
  },
  researchTools: {
    id: 'Dashboard.researchTools',
    defaultMessage: 'Research Tools',
  },
  nextSteps: {
    id: 'Dashboard.nextSteps',
    defaultMessage: 'Next Steps',
  },
});

export class Dashboard extends Component {
  static propTypes = {
    isSpinnerLoading: PropTypes.bool,
    canViewMaxBorrow: PropTypes.bool,
    percentageCompleted: PropTypes.number,
    applicationId: PropTypes.number,
    isHandhold: PropTypes.bool.isRequired,
    isSharedApplication: PropTypes.bool.isRequired,
    primaryStructure: PropTypes.object,
    readLoanApplication: PropTypes.func.isRequired,
    setApplicationTested: PropTypes.func.isRequired,
    setNextPath: PropTypes.func.isRequired,
    requestMaxBorrow: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    startAnimationSequence: PropTypes.func.isRequired,
    sendLoanApplicationForReview: PropTypes.func.isRequired,
    loadStructure: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    loanAmount: PropTypes.number,
    isCustomerCare: PropTypes.bool,
    advisor: PropTypes.object,
    advisorOrg: PropTypes.object,
    contactId: PropTypes.number,
  };

  static defaultProps = {
    percentageCompleted: 0,
    canViewMaxBorrow: false,
    advisor: {},
    advisorOrg: {},
  };

  componentDidMount() {
    const { readLoanApplication } = this.props;
    if (LocalStorageProxy.token) {
      readLoanApplication();
    }

    window.scrollTo(0, 0);
  }

  openPath = (path) => () => {
    this.props.push(path);
  };

  startPreApprovalFlow = () => {
    const {
      startAnimationSequence,
      setApplicationTested,
      sendLoanApplicationForReview,
    } = this.props;

    sendLoanApplicationForReview();
    setApplicationTested(true);
    startAnimationSequence([
      // eslint-disable-next-line sonarjs/no-duplicate-string
      '/ok-emphasis',
      '/send-for-review',
      '/will-find-options',
      '/expect-a-call',
    ]);
  };

  startBorrowingCapacity = () => {
    const {
      startAnimationSequence,
      setNextPath,
      requestMaxBorrow,
      applicationId: loanAppId,
      contactId,
    } = this.props;

    startAnimationSequence(['/ok-emphasis', '/max-borrow-grid']);
    requestMaxBorrow({ isGoalSetter: false, contactId, loanAppId });
    setNextPath(BORROWING_CAPACITY_PATH);
  };

  startInterestSaving = () => {
    const { startAnimationSequence, setNextPath } = this.props;

    startAnimationSequence(['/ok-emphasis', '/searching-grid-savings']);
    setNextPath(INTEREST_SAVING_PATH);
  };

  goToApply = () => {
    const { applicationId, startAnimationSequence, isHandhold } = this.props;

    if (isHandhold) {
      startAnimationSequence(['/ok-emphasis', '/apply-handhold']);
    } else {
      this.openPath(`${APPLY_BASE_PATH}/${applicationId}`)();
    }
  };

  goToCompare = () => {
    const { startAnimationSequence, setNextPath, loanAmount } = this.props;
    const structure = {
      ...DEFAULT_STRUCTURE_INITIAL_STATE,
      loanAmount: loanAmount || DEFAULT_STRUCTURE_INITIAL_STATE.loanAmount,
    };
    setNextPath(`${COMPARE_PAGE_PATH}/?${structureToQueryString(structure)}`);
    startAnimationSequence(['/ok-emphasis', '/searching-grid-compare']);
  };

  getTitle() {
    const {
      intl: { formatMessage },
      isHandhold,
    } = this.props;
    return formatMessage(
      isHandhold ? messages.whereToNow : messages.myDashboard,
    );
  }

  gotToLoanDetails = () => {
    const {
      primaryStructure: { id: structureId, productId },
      loadStructure,
    } = this.props;
    const destinationPath = `${PRODUCT_DETAIL_PATH}/${productId}?source=dashboard`;

    loadStructure(structureId);
    this.openPath(destinationPath)();
  };

  getLoanTileProps() {
    const {
      intl: { formatMessage },
      primaryStructure,
    } = this.props;

    return primaryStructure
      ? {
          title: formatMessage(messages.reviewYourLoan),
          titleDescription: formatProductName(
            primaryStructure.productDetail.name,
          ),
          image: lenderLogoUrl(
            primaryStructure.lenderInfo.key,
            locale.data.countryCode,
          ),
          imageAlt: primaryStructure.lenderInfo.lenderName,
          onClick: this.gotToLoanDetails,
        }
      : {
          title: formatMessage(messages.selectLoan),
          titleDescription: formatMessage(messages.shortlistLoan),
          iconName: 'sl-custom-magnifier',
          onClick: this.openPath(COMPARE_PAGE_PATH),
        };
  }

  getProfileTitle() {
    const {
      intl: { formatMessage },
      percentageCompleted,
    } = this.props;

    return formatMessage(
      percentageCompleted >= 1
        ? messages.viewProfile
        : messages.completeProfile,
    );
  }

  getPreApprovalTitle() {
    const {
      intl: { formatMessage },
      isSharedApplication,
    } = this.props;
    return formatMessage(
      isSharedApplication ? messages.sendToBroker : messages.testPreapproval,
    );
  }

  getBorrowingCapacityDescription() {
    const {
      intl: { formatMessage },
      canViewMaxBorrow,
    } = this.props;
    return formatMessage(
      canViewMaxBorrow
        ? messages.borrowingCapacityDescription
        : messages.borrowingCapacityDescriptionDisabled,
    );
  }

  renderTiles() {
    const {
      intl: { formatMessage },
      percentageCompleted,
      applicationId,
      isSharedApplication,
      canViewMaxBorrow,
    } = this.props;

    return (
      <>
        <div className='nestedRoundCorner'>
          <h5>{formatMessage(messages.yourProfile)}</h5>
          <ApplySummary
            key='completeProfile'
            id='completeProfile'
            title={this.getProfileTitle()}
            titleDescription={formatMessage(
              messages.completeProfileDescription,
              { percentageCompleted },
            )}
            iconName='sl-custom-id-5'
            onClick={this.goToApply}
            theme='standalone'
            noStatus
          />
        </div>
        {!isSharedApplication && locale.isAU && (
          <div className='nestedRoundCorner'>
            <h5>{formatMessage(messages.loans)}</h5>
            {locale.isAU && (
              <ApplySummary
                key='compare'
                id='compare'
                title={formatMessage(messages.compareLoans)}
                titleDescription={formatMessage(
                  messages.compareLoansDescription,
                )}
                iconName='sl-custom-percent-2'
                onClick={this.goToCompare}
                theme='standalone'
                noStatus
              />
            )}
            <ApplySummary
              key='reviewLoan'
              id='reviewLoan'
              {...this.getLoanTileProps()}
              theme='standalone'
              noStatus
            />
          </div>
        )}
        {!isSharedApplication && locale.isAU && (
          <div className='nestedRoundCorner'>
            <h5>{formatMessage(messages.researchTools)}</h5>
            <ApplySummary
              key='borrowingCapacity'
              id='borrowingCapacity'
              title={formatMessage(messages.borrowingCapacity)}
              titleDescription={this.getBorrowingCapacityDescription()}
              iconName='sl-custom-dollar-bag'
              onClick={this.startBorrowingCapacity}
              disabled={!canViewMaxBorrow}
              theme='standalone'
              noStatus
            />
            <ApplySummary
              key='interestSavings'
              id='interestSavings'
              title={formatMessage(messages.interestSavings)}
              titleDescription={formatMessage(
                messages.interestSavingsDescription,
              )}
              iconName='sl-custom-hand-coin'
              onClick={this.startInterestSaving}
              theme='standalone'
              noStatus
            />
          </div>
        )}
        <div className='nestedRoundCorner'>
          <h5>{formatMessage(messages.nextSteps)}</h5>
          <ApplySummary
            key='testPreapproval'
            id={isSharedApplication ? 'sendToBroker' : 'testPreapproval'}
            title={this.getPreApprovalTitle()}
            titleDescription={formatMessage(
              messages.testPreapprovalDescription,
            )}
            iconName='sl-custom-plane-paper-1'
            onClick={this.startPreApprovalFlow}
            theme='standalone'
            noStatus
          />
          <ApplySummary
            key='understandNext'
            id='understandNext'
            title={formatMessage(messages.understandNext)}
            titleDescription={formatMessage(messages.understandNextDescription)}
            iconName='sl-custom-rocket'
            onClick={this.openPath(
              `${APPLICATION_PROGRESS_BASE_PATH}/${applicationId}`,
            )}
            theme='standalone'
            noStatus
          />
        </div>
      </>
    );
  }

  render() {
    const { isSpinnerLoading, advisor, advisorOrg } = this.props;
    const applyBrokerCardProps = { advisor, advisorOrg };
    return (
      <View inverseHeader>
        <meta name='robots' content='noindex, nofollow' />
        <Spinner loading={isSpinnerLoading}>
          <ContentsWrapper id='dashboard' title={this.getTitle()} inverseHeader>
            {this.renderTiles()}
            {!this.props.isCustomerCare && (
              <ApplyBrokerCard {...applyBrokerCardProps} />
            )}
          </ContentsWrapper>
        </Spinner>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  isSpinnerLoading: UISelectors.hasActiveSpinners(state),
  percentageCompleted: completionSelectors.percentageCompleted(state),
  applicationId: applicationSelectors.getApplicationId(state),
  isHandhold: UISelectors.isHandholdOn(state),
  isSharedApplication: applicationSelectors.isSharedApplication(state),
  canViewMaxBorrow: completionSelectors.completedEnoughToViewMaxBorrow(state),
  primaryStructure: structureSelectors.primaryStructure(state),
  loanAmount: fundingSelectors.totalLoanRequired(state),
  isCustomerCare: applicationSelectors.isBrokerCustomerCare(state),
  contactId: clientSelectors.primaryApplicantContactId(state),
  advisor: state.advisor,
  advisorOrg: state.advisorOrg,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      readLoanApplication: loanApplicationActions.readLoanApplication,
      startAnimationSequence: UIActions.startAnimationSequence,
      setApplicationTested: UIActions.setApplicationTested,
      setNextPath: UIActions.setNextPath,
      sendLoanApplicationForReview:
        loanApplicationActions.sendLoanApplicationForReview,
      loadStructure: structureActions.loadStructure,
      requestMaxBorrow: otherActions.requestMaxBorrow,
    },
    dispatch,
  );

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard),
);
