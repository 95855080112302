import React, { Component } from 'react';
import PropTypes from 'prop-types';

const getName = (component) =>
  component.displayName || component.name || 'Component';

const sanityCheck = (WrappedComponent) =>
  class SanityCheck extends Component {
    static displayName = `SanityCheck(${getName(WrappedComponent)})`;

    static propTypes = {
      history: PropTypes.object,
    };

    render() {
      if (!WrappedComponent.isRequiredStructure) {
        throw new Error(
          `${getName(
            WrappedComponent,
          )} needs static function isRequiredStructure`,
        );
      }

      if (WrappedComponent.isRequiredStructure(this.props)) {
        return <WrappedComponent {...this.props} />;
      }

      if (this.props.history) {
        this.props.history.push('/');
      } else {
        window.location.href = '/';
      }
      return null;
    }
  };

export default sanityCheck;
