import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SharedApplyBrokerCard from '@bit/loanmarket.share.components.apply-broker-card';

import LoanMarketLogo from 'components/LoanMarketLogo/LoanMarketLogo';
import SpeechBubble from 'assets/icons/speech-bubble.svg';
import styles from './ApplyBrokerCard.css';

const platform = {
  // eslint-disable-next-line react/display-name
  renderLogo: () => <LoanMarketLogo className={styles.loanMarketLogo} />,
  defaultBrokerImage: SpeechBubble,
};

class ApplyBrokerCard extends Component {
  static propTypes = {
    advisor: PropTypes.object,
    advisorOrg: PropTypes.object,
    clickToCall: PropTypes.func,
  };

  static defaultProps = {
    advisor: {},
    advisorOrg: {},
  };

  render() {
    const { advisor, advisorOrg, clickToCall } = this.props;
    return (
      <SharedApplyBrokerCard
        advisor={advisor}
        advisorOrg={advisorOrg}
        platform={platform}
        clickToCall={clickToCall}
      />
    );
  }
}

export default ApplyBrokerCard;
