import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVGInline from 'react-svg-inline';

import View from 'components/View/View';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import Button from 'components/Button/Button';
import { triggers } from 'constants/campaigns';
import { LOGIN_CAPTURE_PATH } from 'shared/constants/paths';
import GreenTick from 'assets/icons/long-green-tick.svg';

export class UnsubscribePage extends Component {
  static propTypes = {
    match: PropTypes.object,
  };

  render() {
    const {
      match: {
        params: { trigger },
      },
    } = this.props;
    return (
      <View>
        <ContentsWrapper id='unsubscribe' className='unsubscribePage'>
          <div className='rowContainerAboveTablet sameSizeFlexBoxes'>
            <div className='alignCenter'>
              <SVGInline width='10rem' svg={GreenTick} />
            </div>
            <div>
              <h1>You’re unsubscribed from {triggers[trigger]}!</h1>
              <p>We’re here to help when you need us</p>
            </div>
          </div>

          <Button
            className='w49AboveTablet alignCenter brandColor__button'
            url={LOGIN_CAPTURE_PATH}
          >
            Home
          </Button>
        </ContentsWrapper>
      </View>
    );
  }
}

export default UnsubscribePage;
