import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import scenarioActions from 'actions/scenarioActions';
import UIActions from 'actions/UIActions';

import { MULTIPLE_CHOICE_REQUIRED } from 'constants/validators';
import REFINANCE_REASONS from 'shared/constants/refinanceReasons';
import { REFINANCING_DETAILS_PATH } from 'shared/constants/paths';

import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';

import View from 'components/View/View';
import ButtonNext from 'components/ButtonNext/ButtonNext';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import Question from 'components/Question/Question';
import Checklist from 'components/Checklist/Checklist';

class RefinancingReasons extends Component {
  static propTypes = {
    scenario: PropTypes.object.isRequired,
    setRefinanceReasons: PropTypes.func.isRequired,
    setNextPath: PropTypes.func.isRequired,
    startAnimation: PropTypes.func.isRequired,
    ...manageQuestionsPropTypes,
  };

  static questionsToAsk = () => [
    {
      id: 'refinanceReasons',
      branch: 1,
      validation: [MULTIPLE_CHOICE_REQUIRED],
    },
  ];

  static revealMethod = 'steps';
  static revealOverBranch = false;

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSubmit = () => {
    this.props.setNextPath(REFINANCING_DETAILS_PATH);
    this.props.startAnimation(['/more-detail']);
  };

  render() {
    const {
      scenario,
      setRefinanceReasons,
      questions,
      formCompleted,
      setCurrentQuestionTo,
    } = this.props;
    return (
      <View>
        <ContentsWrapper
          id='refinanceReasons'
          title='What are you looking to do?'
          isForm
          formCompleted={formCompleted}
          onSubmit={this.handleSubmit}
          onFocusLost={setCurrentQuestionTo(undefined)}
        >
          <Question
            {...questions.refinanceReasons}
            className='alignLeft'
            showError={false}
          >
            <Checklist
              value={scenario.refinanceReasons}
              items={REFINANCE_REASONS}
              action={setRefinanceReasons}
            />
          </Question>

          <ButtonNext
            id='next'
            disabled={!formCompleted}
            onClick={this.handleSubmit}
          />
        </ContentsWrapper>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  scenario: state.scenario,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRefinanceReasons: scenarioActions.setRefinanceReasons,
      setNextPath: UIActions.setNextPath,
      startAnimation: UIActions.startAnimationSequence,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(manageQuestions(RefinancingReasons, (props) => props.scenario));
