import { pickNumeric } from 'lib/utils/numberUtils';
import {
  MOVING_COST,
  CONVEYANCER_COST,
  COMMISSION_PERCENTAGE,
  RENOVATION_COST,
} from 'shared/constants/defaults';

export default function proceedOfSalesCalculator(property) {
  const propertyValue = property.value;
  const mortgageAmount = property.mortgageAmount;
  const realEstateCommissionPercentage = pickNumeric(
    property.realEstateCommissionPercentage,
    COMMISSION_PERCENTAGE,
  );
  const realEstateCommissionFee = Math.round(
    propertyValue * realEstateCommissionPercentage,
  );
  const adsFee = pickNumeric(
    property.adsFee,
    Math.round(propertyValue * 0.005),
  );
  const movingCost = pickNumeric(property.movingCost, MOVING_COST);

  const realestateFee = realEstateCommissionFee + adsFee;
  const conveyancerCost = pickNumeric(
    property.conveyancerCost,
    CONVEYANCER_COST,
  );
  const renovationCost = pickNumeric(property.renovationCost, RENOVATION_COST);
  const salesFees =
    realestateFee + conveyancerCost + movingCost + renovationCost;
  const saleCost = mortgageAmount + salesFees;
  const equity = propertyValue - saleCost;

  return {
    realEstateCommissionPercentage,
    movingCost,
    adsFee,
    realestateFee,
    conveyancerCost,
    salesFees,
    saleCost,
    equity,
    renovationCost,
  };
}
