import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { push } from '@loan_market/react-router-redux-multi';
import { bindActionCreators, compose } from 'redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

import scenarioActions from 'actions/scenarioActions';
import UIActions from 'actions/UIActions';

import { getProspectiveProperty } from 'shared/lib/propertyHelper';
import { isRefinanceOrRenovate } from 'shared/lib/checkLoanPurpose';

import { formatLoanTypes } from 'constants/loanTypes';
import { formatLoanFeatures, CREDIT_CARD } from 'constants/loanFeatures';
import { REPAYMENT_TYPES_BANKING_PREFERENCES } from 'shared/constants/repaymentTypes';

import {
  PROSPECTIVE_PROPERTY_ID,
  DEFAULT_EXISTING_PROPERTY_ID,
} from 'shared/constants/defaults';
import {
  CONTACT_DETAILS_PATH,
  COMPARE_PAGE_PATH,
} from 'shared/constants/paths';

import { loanParams } from 'lib/loanParamsBuilder';
import LocalStorageProxy from 'lib/localStorageProxy';
import { dataOfMatchingValueByKey } from 'lib/utils/dataUtils';
import Questions from 'lib/Questions';

import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';
import sanityCheck from 'hocs/sanityCheck';

import RadioButtonList from 'components/ButtonList/RadioButtonList';
import Checklist from 'components/Checklist/Checklist';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import Question from 'components/Question/Question';
import View from 'components/View/View';
import ButtonNext from 'components/ButtonNext/ButtonNext';

const messages = defineMessages({
  title: {
    id: 'BankingPreferences.title',
    defaultMessage: 'Let’s talk about features before we start comparing.',
  },
  loanTypeQuestion: {
    id: 'BankingPreferences.loanTypeQuestion',
    defaultMessage: 'What interest rate types are you looking for?',
  },
  loanFeaturesQuestion: {
    id: 'BankingPreferences.loanFeaturesQuestion',
    defaultMessage: 'What loan features are important to you?',
  },
  repaymentTypeQuestion: {
    id: 'BankingPreferences.repaymentTypeQuestion',
    defaultMessage: 'Do you want to repay the principal or interest only?',
  },
});

const questionSet = () => {
  const questions = new Questions();
  questions.addBranch('loanType', 'loanFeatures');
  questions.add('repaymentType');
  return questions.arrayOfQuestions();
};

const propsTransformForQuestion = (props) => ({
  ...getProspectiveProperty(props.scenario),
  loanType: props.scenario.loanType,
  loanFeatures: props.scenario.loanFeatures,
  repaymentType: props.scenario.repaymentType,
});

class BankingPreferences extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    scenario: PropTypes.object.isRequired,
    setLoanType: PropTypes.func.isRequired,
    setLoanFeatures: PropTypes.func.isRequired,
    setRepaymentType: PropTypes.func.isRequired,
    requestLowestRate: PropTypes.func.isRequired,
    startAnimation: PropTypes.func.isRequired,
    setNextPath: PropTypes.func.isRequired,
    ...manageQuestionsPropTypes,
  };

  static questionsToAsk = questionSet;

  static isRequiredStructure = (props) => !!props.scenario.loanPurpose;

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSubmit = () => {
    const {
      scenario,
      requestLowestRate,
      startAnimation,
      setNextPath,
      push: goTo,
    } = this.props;

    requestLowestRate({
      property: isRefinanceOrRenovate(scenario.loanPurpose)
        ? DEFAULT_EXISTING_PROPERTY_ID
        : PROSPECTIVE_PROPERTY_ID,
      loanParams: loanParams(scenario),
    });

    if (LocalStorageProxy.token) {
      setNextPath(COMPARE_PAGE_PATH);
      startAnimation(['/finding-loan']);
    } else if (scenario.id) {
      goTo(CONTACT_DETAILS_PATH);
    } else {
      setNextPath(CONTACT_DETAILS_PATH);
      startAnimation(['/finding-loan']);
    }
  };

  render() {
    const {
      intl,
      scenario,
      setLoanType,
      setLoanFeatures,
      setRepaymentType,
      questions,
    } = this.props;
    const translatedLoanTypes = formatLoanTypes(intl.formatMessage);
    const translatedLoanFeatures = _.remove(
      formatLoanFeatures(intl.formatMessage),
      (item) => item.key !== CREDIT_CARD.key,
    );
    const translatedRepaymentType = REPAYMENT_TYPES_BANKING_PREFERENCES.map(
      (v) => _.omit(v, 'tooltip'),
    );
    const loanTypeHint = dataOfMatchingValueByKey(
      translatedLoanTypes,
      scenario.loanType,
      'hint',
    );
    const repaymentHint = dataOfMatchingValueByKey(
      translatedRepaymentType,
      scenario.repaymentType,
      'hint',
    );

    return (
      <View>
        <ContentsWrapper
          id='bankingPreferences'
          title={intl.formatMessage(messages.title)}
          isForm
          formCompleted
          onSubmit={this.handleSubmit}
        >
          <Question
            {...questions.loanType}
            label={intl.formatMessage(messages.loanTypeQuestion)}
            direction='row'
            hint={loanTypeHint}
          >
            <RadioButtonList
              items={translatedLoanTypes}
              action={setLoanType}
              value={scenario.loanType}
              theme='radioToggle'
            />
          </Question>
          <Question
            {...questions.loanFeatures}
            label={intl.formatMessage(messages.loanFeaturesQuestion)}
          >
            <Checklist
              value={scenario.loanFeatures}
              action={setLoanFeatures}
              items={translatedLoanFeatures}
            />
          </Question>
          <Question
            {...questions.repaymentType}
            label={intl.formatMessage(messages.repaymentTypeQuestion)}
            direction='row'
            hint={repaymentHint}
          >
            <RadioButtonList
              value={scenario.repaymentType}
              action={setRepaymentType}
              items={translatedRepaymentType}
              theme='radioToggle'
            />
          </Question>
          <ButtonNext id='next' onClick={this.handleSubmit} />
        </ContentsWrapper>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({ scenario: state.scenario });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLoanType: scenarioActions.setLoanType,
      setLoanFeatures: scenarioActions.setLoanFeatures,
      setRepaymentType: scenarioActions.setRepaymentType,
      requestLowestRate: scenarioActions.requestLowestRate,
      startAnimation: UIActions.startAnimationSequence,
      setNextPath: UIActions.setNextPath,
      push,
    },
    dispatch,
  );

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  sanityCheck,
  manageQuestions,
)(BankingPreferences, propsTransformForQuestion);
