/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import scenarioActions from 'actions/scenarioActions';
import UIActions from 'actions/UIActions';

import {
  NEGATIVE_CHART_SPECTRUM,
  POSITIVE_CHART_SPECTRUM,
  SUMMARY_SECTION_COLOUR,
} from 'constants/colours';

import { getDefaultExistingProperty } from 'shared/lib/propertyHelper';

import { bindIndividualActions } from 'lib/actionHelpers';
import { saleReportTitleCopy } from 'lib/copyHelper';

import proceedOfSalesCalculator from 'lib/proceedOfSalesCalculator';
import { TO_ABOUT_THE_PROPERTY } from 'lib/pathHelper';

import sanityCheck from 'hocs/sanityCheck';

import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import View from 'components/View/View';
import ButtonNext from 'components/ButtonNext/ButtonNext';
import ReportContent from 'components/ReportContent/ReportContent';

import { existingPropertyEquity } from 'selectors/scenarioSelectors';
import {
  hydrateMetadata,
  targetDefaultExistingProperty,
  salesReportSellingCosts,
} from 'selectors/reportSelectors';
import {
  salesPriceMetadata,
  proceedsOfSaleMetadata,
} from 'constants/reportMetadata';

class SaleReport extends Component {
  static propTypes = {
    scenario: PropTypes.object.isRequired,
    equity: PropTypes.number,
    salesPrice: PropTypes.object.isRequired,
    sellingCosts: PropTypes.arrayOf(PropTypes.object.isRequired),
    proceedsOfSale: PropTypes.object.isRequired,
    mergePropertyReportData: PropTypes.func.isRequired,
    goToPathWithAnimation: PropTypes.func.isRequired,
  };

  static isRequiredStructure = (props) =>
    !!getDefaultExistingProperty(props.scenario);

  // TODO: this merge property report data should be made redundant by selectors
  componentDidMount() {
    const { mergePropertyReportData, scenario } = this.props;
    const property = getDefaultExistingProperty(scenario);
    mergePropertyReportData(property.id)(proceedOfSalesCalculator(property));

    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const thisProperty = getDefaultExistingProperty(this.props.scenario);
    const nextProperty = getDefaultExistingProperty(nextProps.scenario);
    if (!_.isEqual(thisProperty, nextProperty)) {
      nextProps.mergePropertyReportData(nextProperty.id)(
        proceedOfSalesCalculator(nextProperty),
      );
    }
  }

  handleNextClick = (e) => {
    // We should only be in here in case new apply inbetweeners are on
    e.preventDefault();
    this.props.goToPathWithAnimation(TO_ABOUT_THE_PROPERTY);
  };

  renderReport = () => {
    const { equity, salesPrice, sellingCosts, proceedsOfSale } = this.props;
    const isNegativeNews = equity < 0;

    const data = [
      {
        label: 'Sale Price',
        isChartTotal: !isNegativeNews,
        colour: !isNegativeNews ? SUMMARY_SECTION_COLOUR : null,
        isAccordion: false,
        items: [salesPrice],
      },
      {
        label: 'Selling Costs',
        isChartTotal: isNegativeNews,
        colour: isNegativeNews ? SUMMARY_SECTION_COLOUR : null,
        isAccordion: false,
        items: sellingCosts,
      },
      {
        label: 'Proceeds of Sale',
        negative: isNegativeNews,
        isAccordion: false,
        colour: (isNegativeNews
          ? NEGATIVE_CHART_SPECTRUM
          : POSITIVE_CHART_SPECTRUM)[0],
        items: [proceedsOfSale],
      },
    ];
    return (
      <ReportContent
        key='ProceedsOfSale'
        data={data}
        spectrum={(isNegativeNews
          ? NEGATIVE_CHART_SPECTRUM
          : POSITIVE_CHART_SPECTRUM
        ).slice(1)}
        theme='borderReport'
      >
        <ButtonNext id='next' onClick={this.handleNextClick} />
      </ReportContent>
    );
  };

  render() {
    const { equity } = this.props;
    return (
      <View inverseHeader>
        <ContentsWrapper
          id='saleReport'
          title={saleReportTitleCopy(equity)}
          inverseHeader
        >
          {this.renderReport()}
        </ContentsWrapper>
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  scenario: state.scenario,
  equity: existingPropertyEquity(state),
  salesPrice: hydrateMetadata(
    targetDefaultExistingProperty(salesPriceMetadata),
  )(state, ownProps),
  sellingCosts: salesReportSellingCosts(state, ownProps),
  proceedsOfSale: hydrateMetadata(proceedsOfSaleMetadata)(state, ownProps),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      goToPathWithAnimation: UIActions.goToPathWithAnimation,
    },
    dispatch,
  ),
  ...bindIndividualActions(
    {
      mergePropertyReportData: scenarioActions.mergePropertyReportData,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(sanityCheck(SaleReport));
