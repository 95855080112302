import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import HelpPage from 'containers/HelpPage';
import themes from 'components/FullBgContentsWrapper/FullBgContentsWrapper.css';

const NextStepsPage = (props) => {
  const { adviser } = props;
  return (
    <HelpPage
      theme={themes.helpPage}
      title={`${
        adviser.preferredName || adviser.name || 'We'
      } will be in touch with next steps`}
      description=''
    />
  );
};

NextStepsPage.propTypes = {
  adviser: PropTypes.object,
};

const mapStateToProps = (state) => ({
  adviser: state.advisor,
});

export default connect(mapStateToProps, null)(NextStepsPage);
