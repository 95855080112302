/* eslint-disable react/jsx-handler-names */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from '@loan_market/react-router-redux-multi';

import scenarioActions from 'actions/scenarioActions';
import UIActions from 'actions/UIActions';

import {
  FIRST_HOME_OWNERS_GRANT_PATH,
  LOAN_REPORT_PATH,
} from 'shared/constants/paths';
import {
  BUYER_SCENARIO_FHB,
  INVESTMENT_SCENARIO_DEFAULT,
} from 'shared/constants/loanScenarios';
import { PROSPECTIVE_PROPERTY_ID } from 'shared/constants/defaults';
import propertyTypeOptions from 'constants/propertyTypeOptions';

import { getProspectiveProperty } from 'shared/lib/propertyHelper';
import generatePurchaseCostParams from 'lib/purchaseCostParamsBuilder';

import sanityCheck from 'hocs/sanityCheck';

import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import Question from 'components/Question/Question';
import View from 'components/View/View';
import ButtonList from 'components/ButtonList/ButtonList';

export class StampDuty extends Component {
  static propTypes = {
    scenario: PropTypes.object.isRequired,
    setPropertyType: PropTypes.func.isRequired,
    requestPurchaseCost: PropTypes.func.isRequired,
    setNextPath: PropTypes.func.isRequired,
    startAnimation: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    push: PropTypes.func.isRequired,
  };

  static isRequiredStructure = (props) =>
    !!getProspectiveProperty(props.scenario);

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSubmitAction = (value) => {
    const {
      setPropertyType,
      requestPurchaseCost,
      setNextPath,
      startAnimation,
      scenario,
    } = this.props;
    let nextPath;
    let inbetweener;
    let sendRequest = false;

    setPropertyType(value);

    if (scenario.name === BUYER_SCENARIO_FHB) {
      nextPath = FIRST_HOME_OWNERS_GRANT_PATH;
      inbetweener = ['/great', '/first-home-grant'];
    } else {
      nextPath = LOAN_REPORT_PATH;
      inbetweener =
        scenario.name === INVESTMENT_SCENARIO_DEFAULT
          ? ['/ok', '/calculator']
          : ['/got-it', '/calculator'];
      sendRequest = true;
    }

    setNextPath(nextPath);
    startAnimation(inbetweener);

    if (sendRequest) {
      requestPurchaseCost({
        params: {
          ...generatePurchaseCostParams(scenario),
          propertyDesc: value,
        },
      });
    }
  };

  render() {
    return (
      <View>
        <ContentsWrapper
          id='stampDuty'
          title='What kind of property will you be buying?'
        >
          <Question
            id='propertyType'
            formCompleted
            direction='column'
            className='alignLeft'
          >
            <ButtonList
              items={propertyTypeOptions}
              action={this.handleSubmitAction}
            />
          </Question>
        </ContentsWrapper>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  scenario: state.scenario,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPropertyType: scenarioActions.setPropertyType(PROSPECTIVE_PROPERTY_ID),
      requestPurchaseCost: scenarioActions.requestPurchaseCost,
      setNextPath: UIActions.setNextPath,
      startAnimation: UIActions.startAnimationSequence,
      push,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(sanityCheck(StampDuty));
