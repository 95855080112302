import houseSprite from '../../assets/buttons/small-house.png';
import buildingSprite from '../../assets/buttons/small-building.png';
import handSprite from '../../assets/buttons/small-handcoins.png';

const AnimationConfig = {
  'small-house': {
    sprite: houseSprite,
    width: 180,
    height: 180,
    scale: 0.9,
    fps: 25,
    frameCount: 84,
    wrapAfter: 5,
    animations: {
      default: {
        startFrame: 0,
        endFrame: 84,
        stopLastFrame: true,
      },
      hover: {
        startFrame: 43,
        endFrame: 84,
        stopLastFrame: false,
      },
      afterHover: {
        startFrame: 83,
        endFrame: 83,
        stopLastFrame: true,
      },
    },
  },
  'small-building': {
    sprite: buildingSprite,
    width: 180,
    height: 180,
    scale: 0.9,
    fps: 25,
    frameCount: 50,
    wrapAfter: 5,
    animations: {
      default: {
        startFrame: 0,
        endFrame: 50,
        stopLastFrame: true,
      },
      hover: {
        startFrame: 31,
        endFrame: 50,
        stopLastFrame: false,
      },
      afterHover: {
        startFrame: 49,
        endFrame: 49,
        stopLastFrame: true,
      },
    },
  },
  'small-handcoins': {
    sprite: handSprite,
    width: 180,
    height: 180,
    scale: 0.9,
    fps: 25,
    frameCount: 134,
    wrapAfter: 11,
    animations: {
      default: {
        startFrame: 0,
        endFrame: 134,
        stopLastFrame: true,
      },
      hover: {
        startFrame: 43,
        endFrame: 134,
        stopLastFrame: false,
      },
      afterHover: {
        startFrame: 133,
        endFrame: 133,
        stopLastFrame: true,
      },
    },
  },
};

export default AnimationConfig;
