/* eslint-disable unicorn/consistent-function-scoping */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, intlShape, defineMessages } from 'react-intl';

import * as UISelectors from 'selectors/UISelectors';
import scenarioActions from 'actions/scenarioActions';

import { handleEnter } from 'lib/utils/browserUtils';
import { logger as coreLogger } from 'lib/coreLogger';

import View from 'components/View/View';
import Spinner from 'components/Spinner/Spinner';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import NotificationBanner from 'components/NotificationBanner/NotificationBanner';
import Question from 'components/Question/Question';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';

import NotifBannerStyles from 'components/NotificationBanner/NotificationBanner.css';

const messages = defineMessages({
  title: {
    id: 'PasswordReset.title',
    defaultMessage: 'Ok! Let’s get your password sorted',
  },
  emailAddress: {
    id: 'PasswordReset.emailAddress',
    defaultMessage: 'Please enter & confirm your email address',
  },
  confirm: {
    id: 'PasswordReset.confirm',
    defaultMessage: 'Confirm',
  },
});

export const PasswordReset = (props) => {
  const logger = coreLogger('PasswordReset');
  const {
    isSpinnerLoading,
    intl: { formatMessage },
    error,
    resetPasswordEmail,
    requestResetPasswordLink,
    setResetPasswordEmail,
    setError,
  } = props;
  const [emailAddress, setEmailAddress] = useState(resetPasswordEmail);

  const handleSubmit = () => {
    logger.info({
      action: 'request reset password link',
      data: { emailAddress },
    });
    requestResetPasswordLink(emailAddress);
  };

  const clearStorage = () => {
    localStorage.clear();
    sessionStorage.clear();
  };

  useEffect(() => {
    window.addEventListener('beforeunload', clearStorage);
    logger.info({
      action: 'on password reset page',
      data: { emailAddress },
    });
    return () => {
      setError({
        id: 'resetPassword',
        text: null,
      });
      setResetPasswordEmail('');
      window.removeEventListener('beforeunload', clearStorage);
    };
  }, []);

  return (
    <View inverse>
      <Spinner loading={isSpinnerLoading}>
        <ContentsWrapper
          id='passwordReset'
          className='halfwidth'
          title={formatMessage(messages.title)}
          inverseHeader
          centeredTitle
        >
          {error && error.text && (
            <NotificationBanner
              message={error.text}
              className={NotifBannerStyles.passwordError}
            />
          )}
          <Question
            id='resetPasswordEmail'
            label={formatMessage(messages.emailAddress)}
            className='fullWidthInput'
            theme='noPaddingTopBottom'
          >
            <Input
              action={setEmailAddress}
              value={emailAddress}
              onKeyDown={handleEnter(handleSubmit)}
              type='email'
            />
          </Question>

          <Button
            onClick={handleSubmit}
            theme='transparent'
            className='brandColor__font--hover'
            disabled={!emailAddress || emailAddress.trim().length === 0}
          >
            {formatMessage(messages.confirm)}
          </Button>
        </ContentsWrapper>
      </Spinner>
    </View>
  );
};

PasswordReset.propTypes = {
  intl: intlShape.isRequired,
  isSpinnerLoading: PropTypes.bool,
  resetPasswordEmail: PropTypes.string,
  error: PropTypes.object,
  requestResetPasswordLink: PropTypes.func.isRequired,
  setResetPasswordEmail: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isSpinnerLoading: UISelectors.hasActiveSpinners(state),
  resetPasswordEmail: state.scenario.resetPasswordEmail,
  error: state.scenario.errors.resetPassword,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestResetPasswordLink: scenarioActions.requestResetPasswordLink,
      setResetPasswordEmail: scenarioActions.setResetPasswordEmail,
      setError: scenarioActions.setError,
    },
    dispatch,
  );

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(PasswordReset),
);
