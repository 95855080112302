import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from '@loan_market/react-router-redux-multi';

import locale from 'config/locale';
import LocalStorageProxy from 'lib/localStorageProxy';
import * as UISelectors from 'selectors/UISelectors';
import UIActions from 'actions/UIActions';
import scenarioActions from 'actions/scenarioActions';
import { NO_ERROR } from 'constants/validators';

import Spinner from 'components/Spinner/Spinner';
import FullBgContentsWrapper from 'components/FullBgContentsWrapper/FullBgContentsWrapper';
import View from 'components/View/View';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import HelpContactInfo from 'components/HelpContactInfo/HelpContactInfo';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

import ButtonStyles from 'components/Button/Button.css';
import QuestionStyles from 'components/Question/Question.css';
import { serializeQueriesForUrl } from 'lib/utils/browserUtils';
import { logger as coreLogger } from 'lib/coreLogger';
import AUTH_INTENTS from 'constants/authIntents';
import { OKTA_STATUSES } from 'shared/constants/okta';
import {
  LOGIN_FOR_SIGNUP_PATH,
  LOGIN_CAPTURE_PATH,
  PASSWORD_RESET_PATH,
} from 'shared/constants/paths';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const HelpPage = (props) => {
  const logger = coreLogger('HelpPage');
  const [userEmail, setUserEmail] = useState('');
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const {
    isSpinnerLoading,
    showLogin = true,
    adviserOrg,
    adviser,
    showConfirmEmail,
    requestConfirmEmail,
    description = 'We’re here to help.',
    error,
    setError,
    goTo,
    showLoginButton,
    intent = AUTH_INTENTS.LOGIN,
    oktaStatus,
    recoveryToken,
    sharedQueryParams,
    removeSpinners,
    redirect,
    clearErrors,
    showContactInfo = true,
  } = props;

  const { officeEmail, officePhone } = adviserOrg;
  const {
    name,
    email = officeEmail,
    mobile = officePhone || locale.data.phoneNumber,
  } = adviser;
  const title = emailConfirmed
    ? `Thanks! Check your email and we’ll get your profile set up.`
    : props.title || 'Got any questions?';
  const helpDescription = emailConfirmed ? '' : description;
  const theme = props.theme || 'helpPage';

  useEffect(() => {
    removeSpinners();
    clearErrors();
    logger.info({
      action: 'on help page',
      data: { intent, title: props.title, description },
    });
  }, []);

  const onConfirmEmailClick = () => {
    logger.info({
      action: 'resend OFF invite',
    });
    requestConfirmEmail({ email: userEmail, setEmailConfirmed });
  };

  const onEmailChange = (value) => {
    setError({ id: 'confirmEmail', text: NO_ERROR });
    setUserEmail(value);
  };

  const renderConfirmEmail = () => {
    if (emailConfirmed) {
      return (
        <>
          <br />
          <p>
            Didn’t get an email?{' '}
            <a onClick={() => setEmailConfirmed(false)}>Click here.</a>
          </p>
        </>
      );
    }
    return (
      <>
        <Input type='email' action={onEmailChange} value={userEmail} />
        {error && (
          <ErrorMessage className={QuestionStyles.error} message={error.text} />
        )}
        <Button
          onClick={onConfirmEmailClick}
          className={ButtonStyles.confirmButton}
          disabled={!userEmail}
        >
          Get started
        </Button>
      </>
    );
  };

  const redirectToCurrentSession = () => {
    let path = `${LOGIN_CAPTURE_PATH}${
      redirect ? `?redirect=${encodeURIComponent(redirect)}` : ''
    }`;
    if (intent === AUTH_INTENTS.RESET_PASSWORD) {
      path = `${PASSWORD_RESET_PATH}?token=${recoveryToken}`;
    }
    if (intent === AUTH_INTENTS.SIGNUP) {
      if (oktaStatus === OKTA_STATUSES.PASSWORD_RESET) {
        path = `${LOGIN_CAPTURE_PATH}?${serializeQueriesForUrl(
          sharedQueryParams,
        )}`;
      } else {
        path = LOGIN_FOR_SIGNUP_PATH;
      }
    }
    if (intent === AUTH_INTENTS.LOGIN && !showLogin) {
      LocalStorageProxy.clearAll();
    }
    logger.info({
      action: 'redirect to current session',
      data: { intent, showLogin, path },
    });
    goTo(path);
  };

  useEffect(() => {
    setError({ id: 'confirmEmail', text: NO_ERROR });
  }, [setError]);

  const renderContactInfo = () =>
    showContactInfo ? (
      <HelpContactInfo name={name} email={email} mobile={mobile} />
    ) : (
      <></>
    );

  return (
    <View
      theme='sharedLogin'
      showLogin={showLogin}
      showCallSection={false}
      showMenu={false}
    >
      <Spinner loading={isSpinnerLoading}>
        <FullBgContentsWrapper title={title} theme={theme}>
          <>
            {helpDescription && (
              <p data-testid='help.page.description'>{helpDescription}</p>
            )}
            {!showLoginButton &&
              (showConfirmEmail ? renderConfirmEmail() : renderContactInfo())}
            {showLoginButton && (
              <Button
                className={ButtonStyles.loginButton}
                onClick={redirectToCurrentSession}
              >
                {intent === AUTH_INTENTS.SIGNUP
                  ? 'Sign up again'
                  : 'Log in again'}
              </Button>
            )}
          </>
        </FullBgContentsWrapper>
      </Spinner>
    </View>
  );
};

HelpPage.propTypes = {
  isSpinnerLoading: PropTypes.bool,
  adviserOrg: PropTypes.object,
  adviser: PropTypes.object,
  showLogin: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  theme: PropTypes.string,
  showConfirmEmail: PropTypes.bool,
  requestConfirmEmail: PropTypes.func,
  error: PropTypes.object,
  setError: PropTypes.func,
  showLoginButton: PropTypes.bool,
  goTo: PropTypes.func,
  intent: PropTypes.string,
  oktaStatus: PropTypes.string,
  recoveryToken: PropTypes.string,
  sharedQueryParams: PropTypes.object,
  removeSpinners: PropTypes.func,
  redirect: PropTypes.string,
  clearErrors: PropTypes.func,
  showContactInfo: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const {
    errors,
    oktaData,
    recoveryToken,
    utm,
    sharedLoanAppId,
    sharedClientId,
  } = state.scenario;
  return {
    isSpinnerLoading: UISelectors.hasActiveSpinners(state),
    adviserOrg: state.advisorOrg,
    adviser: state.advisor,
    error: errors.confirmEmail,
    oktaStatus: oktaData.status,
    recoveryToken,
    sharedQueryParams: {
      recovery: recoveryToken,
      clientId: sharedClientId,
      loanApplicationId: sharedLoanAppId,
      ...utm,
    },
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestConfirmEmail: scenarioActions.requestConfirmEmail,
      setError: scenarioActions.setError,
      goTo: push,
      removeSpinners: UIActions.removeSpinners,
      clearErrors: UIActions.clearAsyncRequestErrors,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(HelpPage);
