export function lowestRateDisclaimer(lowestRate, ownerOccupied) {
  const type = ownerOccupied ? 'home loan' : 'investment';
  return `*${lowestRate}% p.a. is our lowest advertised ${type} interest rate when comparing our panel of lenders, which features more than 30 of Australia’s biggest banks and specialist lenders. When comparing home loan costs always refer to both the lenders advertised interest rate, and the comparison rate (which is either the same, or in most cases higher). A comparison rate is a tool to help consumers identify the true cost of a loan. It factors in the interest rate, loan amount, repayment term and ongoing fees and charges and displays this as a single percentage rate that can be used to compare various loans from different lenders.`;
}

export function generateDisclaimer(property) {
  if (
    !property ||
    !(
      property.investmentLowestRateManual ||
      (property.lowestRateProduct && property.lowestRateProduct.interestRate)
    )
  ) {
    return [];
  }
  return [
    lowestRateDisclaimer(
      property.investmentLowestRateManual ||
        property.lowestRateProduct.interestRate,
      property.ownerOccupied,
    ),
  ];
}
