import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { omit } from 'lodash';

import UIActions from 'actions/UIActions';

import { generateUniqueID } from 'lib/utils/stringUtils';
import * as SpinnerNames from 'constants/spinnerNames';

export class Iframe extends Component {
  static propTypes = {
    pushSpinner: PropTypes.func.isRequired,
    popSpinner: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    useObject: PropTypes.bool,
    useSpinner: PropTypes.bool,
    onLoad: PropTypes.func,
    handleIframeMessage: PropTypes.func,
  };

  static defaultProps = {
    useObject: true,
    useSpinner: true,
  };

  componentDidMount() {
    this.startSpinner();

    window.addEventListener('message', this.handleIframeMessage, false);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.src !== this.props.src) {
      this.startSpinner();
    }
  }

  shouldComponentUpdate(newProps) {
    // Do not update iframe unless the src url has changed as it will reload the url
    return newProps.src !== this.props.src;
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleIframeMessage, false);
  }

  startSpinner = () => {
    if (this.props.useSpinner) {
      this.props.pushSpinner(SpinnerNames.IFRAME);
    }
  };

  stopSpinner = () => {
    if (this.props.useSpinner) {
      this.props.popSpinner(SpinnerNames.IFRAME);
    }
  };

  id() {
    const { id } = this.props;
    return generateUniqueID('Iframe', id);
  }

  handleIframeMessage = (e) => {
    const { handleIframeMessage } = this.props;
    if (handleIframeMessage) {
      handleIframeMessage(e.data);
    }
  };

  handleOnLoad = (e) => {
    const { onLoad } = this.props;

    this.stopSpinner();
    if (onLoad) {
      onLoad(e);
    }
  };

  renderObject(commonProps) {
    return <object type='text/html' data={this.props.src} {...commonProps} />;
  }

  renderIframe(commonProps) {
    return <iframe src={this.props.src} {...commonProps} />;
  }

  render() {
    const { src, useObject, ...iframeProps } = this.props;
    const commonProps = {
      ...omit(iframeProps, [
        'pushSpinner',
        'popSpinner',
        'useObject',
        'useSpinner',
        'onLoad',
        'id',
        'handleIframeMessage',
      ]),
      id: this.id(),
      width: '100%',
      sandbox: 'allow-scripts allow-same-origin allow-popups allow-forms',
      frameBorder: '0',
      scrolling: 'yes',
      onLoad: this.handleOnLoad,
      ref: (iframe) => {
        this.iframeRef = iframe;
      },
    };

    return useObject
      ? this.renderObject(commonProps)
      : this.renderIframe(commonProps);
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushSpinner: UIActions.pushSpinner,
      popSpinner: UIActions.popSpinner,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Iframe);
