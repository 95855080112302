import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import classNames from 'classnames';
import locale from 'config/locale';

import Button from 'components/Button/Button';
import { lenderReversedLogoUrl } from 'lib/utils/imageUtils';
import styles from './BankList.css';

export const messages = defineMessages({
  bigFourHeader: {
    id: 'BankList.bigFourHeader',
    defaultMessage: 'Big Four',
  },
  othersHeader: {
    id: 'BankList.othersHeader',
    defaultMessage: 'Other Banks',
  },
  cantFindBank: {
    id: 'BankList.cantFindBank',
    defaultMessage: 'Can’t find your bank?',
  },
  skipStep: {
    id: 'BankList.skipStep',
    defaultMessage: 'Skip this step',
  },
});

export class BankList extends Component {
  static propTypes = {
    bankList: PropTypes.shape({
      bigFour: PropTypes.array.isRequired,
      others: PropTypes.array.isRequired,
    }).isRequired,
    requestBankLogin: PropTypes.func.isRequired,
    setBankDetails: PropTypes.func.isRequired,
    onSkip: PropTypes.func,
    intl: intlShape,
  };

  constructor(props) {
    super(props);
    this.state = {
      lenderImageErrorIds: [],
    };
  }

  startLogin = (bank) => () => {
    const { requestBankLogin, setBankDetails } = this.props;
    setBankDetails(bank);
    requestBankLogin({
      id: bank.id,
      name: bank.name,
      workingId: 'new', // TODO: Remove this from here and saga
    });
  };

  handleImageError = (id) => () => {
    this.setState({
      lenderImageErrorIds: [...this.state.lenderImageErrorIds, id],
    });
  };

  renderBankButton = (bank) => {
    const logoUrl = lenderReversedLogoUrl(
      bank.milliLenderId,
      locale.data.countryCode,
      bank.id,
    );
    const hasImage =
      bank.id && !this.state.lenderImageErrorIds.includes(bank.id);
    return (
      <Button
        key={bank.id}
        onClick={this.startLogin(bank)}
        className={
          bank.key ? styles[`button${bank.key}`] : 'brandColor__reverseButton'
        }
      >
        {hasImage ? (
          <img
            className={styles.bankButton}
            src={logoUrl}
            alt={bank.name}
            onError={this.handleImageError(bank.id)}
          />
        ) : (
          <div className={classNames(styles.bankButton, styles.textOnly)}>
            {bank.name}
          </div>
        )}
      </Button>
    );
  };

  render() {
    const {
      intl: { formatMessage },
      bankList,
      onSkip,
    } = this.props;
    return (
      <div className={styles.bankList}>
        <p className={styles.header}>{formatMessage(messages.bigFourHeader)}</p>
        {bankList.bigFour.map((bank) => this.renderBankButton(bank))}
        <p className={styles.header}>{formatMessage(messages.othersHeader)}</p>
        {bankList.others.map((bank) => this.renderBankButton(bank))}
        {onSkip && <p>{formatMessage(messages.cantFindBank)}</p>}
        {onSkip && (
          <Button
            id='skipBankConnectButton'
            theme='linkButton'
            onClick={onSkip}
          >
            {formatMessage(messages.skipStep)}
          </Button>
        )}
      </div>
    );
  }
}

export default injectIntl(BankList);
