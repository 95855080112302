import React from 'react';
import SharedButtonList from '@bit/loanmarket.share.components.button-list';
import PropTypes from 'prop-types';
import AnimationConfig from './AnimationConfig';

export const ButtonList = ({ action, animated, id, items, theme }) => {
  return (
    <SharedButtonList
      action={action}
      animated={animated}
      animationConfig={AnimationConfig}
      id={id}
      items={items}
      theme={theme}
    />
  );
};

ButtonList.displayName = 'ButtonList';

ButtonList.propTypes = {
  action: PropTypes.func.isRequired,
  animated: PropTypes.bool,
  id: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  theme: PropTypes.string,
};

export default ButtonList;
