/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push, goBack } from '@loan_market/react-router-redux-multi';
import _ from 'lodash';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import Truncate from 'react-truncate';

import productsActions from 'actions/productsActions';
import structureActions from 'actions/structureActions';

import { PRODUCT_ORDER_OPTIONS } from 'shared/constants/productOrderOptions';
import lenders from 'shared/lib/lenderHelper';
import { DEFAULT_STRUCTURE_INITIAL_STATE } from 'shared/constants/defaults';

import { comparePageFilterCopies } from 'lib/copyHelper';
import { fromLoanToApply } from 'lib/pathHelper';
import {
  buildStructureFromQueryString,
  structureToQueryString,
  hasStructureChanged,
  isGroupingProducts,
} from 'lib/compareHelper';
import getLender from 'lib/lenderHelper';
import { findOption } from 'lib/optionHelpers';

import * as productSelectors from 'selectors/productSelectors';
import * as fundingSelectors from 'selectors/fundingSelectors';
import * as goalLoanAppInfoSelectors from 'selectors/goalLoanAppInfoSelectors';
import { getIsLocked } from 'selectors/applicationSelectors';

import View from 'components/View/View';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import Spinner from 'components/Spinner/Spinner';
import ButtonWithDescription from 'components/ButtonWithDescription/ButtonWithDescription';
import ProductList from 'components/ProductList/ProductList';
import RepaymentsExplanation from 'components/RepaymentsExplanation/RepaymentsExplanation';
import ComparePageNavigator from 'components/ComparePageNavigator/ComparePageNavigator';
import ProductFilter from 'components/ProductFilter/ProductFilter';
import ComparePageBanner from 'components/ComparePageBanner/ComparePageBanner';
import * as structureSelectors from 'selectors/structureSelectors';
import * as UISelectors from 'selectors/UISelectors';

const messages = defineMessages({
  queryLoanAmountText: {
    id: 'ProductComparePage.loanAmountQuery.text',
    defaultMessage: '$ {loanAmount, number}',
  },
  queryLoanTermText: {
    id: 'ProductComparePage.loanTermQuery.text',
    defaultMessage:
      '{loanTerm, number} {loanTerm, plural, one {Year} other {Years}} Term',
  },
  refineSearch: {
    id: 'ProductComparePage.refineSearch.text',
    defaultMessage: 'Refine Search',
  },
  back: {
    id: 'ProductComparePage.back.text',
    defaultMessage: 'Back',
  },
  singleLenderBannerFooterText: {
    id: 'ProductComparePage.singleLenderBannerFooterText.text',
    defaultMessage: `{numberOfResults} {numberOfResults, plural, =1 {result} =0 {no results} other {results}}`,
  },
});

class ComparePage extends Component {
  static propTypes = {
    isSpinnerLoading: PropTypes.bool,
    isLocked: PropTypes.bool.isRequired,
    hasMoreLenderProducts: PropTypes.bool,
    isLoadingMoreProducts: PropTypes.bool,
    requestSortedProducts: PropTypes.func.isRequired,
    loanApplicationId: PropTypes.number,
    structure: PropTypes.object,
    primaryStructure: PropTypes.object,
    structureUpdated: PropTypes.bool,
    products: PropTypes.object.isRequired,
    requestProducts: PropTypes.func.isRequired,
    requestMoreProducts: PropTypes.func,
    updateWorkingStructure: PropTypes.func,
    loadStructure: PropTypes.func,
    saveStructure: PropTypes.func,
    selectProduct: PropTypes.func,
    intl: intlShape.isRequired,
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    loanAmount: PropTypes.number,
    history: PropTypes.object,
    accreditedLenderIds: PropTypes.arrayOf(PropTypes.object),
  };

  constructor(props) {
    super(props);
    this.state = {
      isShowFilters: false,
    };
  }

  componentWillUnmount() {
    this.props.updateWorkingStructure({ page: 1 });
  }

  componentDidMount() {
    const {
      structure,
      loadStructure,
      primaryStructure,
      requestProducts,
    } = this.props;
    if (primaryStructure && !structure.id) {
      // handle direct land in from drop down menu
      loadStructure(primaryStructure.id);
    }
    requestProducts();
  }

  componentDidUpdate(prevProps) {
    const {
      requestProducts,
      updateWorkingStructure,
      structure,
      history: {
        location: { search },
      },
      push: goTo,
      loanAmount,
    } = prevProps;
    const {
      structure: nextStructure,
      history: {
        location: { search: nextSearch },
      },
    } = this.props;
    if (hasStructureChanged(structure, nextStructure)) {
      // if structure reducer has changed we update the location to reflect the changes.
      const queryString = `?${structureToQueryString(nextStructure)}`;
      if (queryString !== search) {
        goTo(`/compare-page/${queryString}`);
      }
    } else if (search.length > 0 && search !== nextSearch) {
      // otherwise if search has changed we update structure to match
      // if no structure found in search we default to initial structure.
      const newStructure = nextSearch.length
        ? buildStructureFromQueryString(nextSearch)
        : { ...DEFAULT_STRUCTURE_INITIAL_STATE, loanAmount };
      updateWorkingStructure(newStructure);
      requestProducts();
    }
  }

  getQueryParams = (itemsWithCopy) => {
    const {
      structure: { selectedLenderIds, loanAmount, loanTerm },
      intl: { formatMessage },
    } = this.props;
    const lenderNames =
      selectedLenderIds.length > 0
        ? lenders.userPanel
            .reduce(
              (p, c) =>
                selectedLenderIds.includes(c.lenderId)
                  ? [...p, c.lenderName]
                  : p,
              [],
            )
            .join(', ')
        : 'All lenders';

    const loanAmountString = formatMessage(messages.queryLoanAmountText, {
      loanAmount,
    });
    const loanTermString = formatMessage(messages.queryLoanTermText, {
      loanTerm,
    });
    return [
      lenderNames,
      loanAmountString,
      loanTermString,
      ..._.map(itemsWithCopy, (v) => v.summary),
    ]
      .filter((a) => a)
      .join(' | ');
  };

  closeFilter = () => this.setState({ isShowFilters: false });

  showFilter = () => this.setState({ isShowFilters: true });

  sortingProduct = (value) => this.props.requestSortedProducts(value);

  submitFilterValues = (newStructure) => {
    this.props.updateWorkingStructure(newStructure);
    this.props.requestProducts();
    this.closeFilter();
  };

  renderTitlePart = () => {
    const {
      structure: { selectedLenderIds },
      intl: { formatMessage },
      products: { productList },
    } = this.props;
    if (selectedLenderIds.length !== 1) {
      return 'Compare Home Loans';
    }
    const selectedLender = getLender(selectedLenderIds[0]);
    const resultCount = (productList && productList.length) || 0;
    return () => (
      <ComparePageBanner
        lenderObject={selectedLender}
        title={`${selectedLender.lenderName} Home Loans - ${formatMessage(
          messages.singleLenderBannerFooterText,
          {
            numberOfResults: resultCount,
          },
        )}`}
      />
    );
  };

  onSelectProduct = (product) => {
    const {
      saveStructure,
      selectProduct,
      structure,
      loanApplicationId,
    } = this.props;

    if (loanApplicationId) {
      saveStructure({
        structure: {
          ...structure,
          loanApplicationId,
          productId: product.id,
          lenderId: product.lender.id,
        },
        nextPath: fromLoanToApply(loanApplicationId),
      });
    } else {
      selectProduct(product);
    }
  };

  onLoadMoreProducts = () => {
    const { structure, requestMoreProducts } = this.props;
    requestMoreProducts(structure.page + 1);
  };

  render() {
    const {
      isSpinnerLoading,
      structure,
      structureUpdated,
      products,
      isLocked,
      hasMoreLenderProducts,
      isLoadingMoreProducts,
      goBack: back,
      intl: { formatMessage },
      accreditedLenderIds,
    } = this.props;

    const { isShowFilters } = this.state;
    return (
      <View>
        <meta name='robots' content='noindex, nofollow' />
        <Spinner loading={isSpinnerLoading}>
          <ContentsWrapper
            id='comparePage'
            title={this.renderTitlePart()}
            animateHeading={false}
          >
            <ButtonWithDescription
              id='queryFilters'
              onClick={this.showFilter}
              icon='sl-custom-magnifier'
              description={
                <div>
                  <Truncate lines={2} ellipsis='...'>
                    {this.getQueryParams(
                      comparePageFilterCopies(formatMessage, structure),
                    )}
                  </Truncate>
                </div>
              }
            >
              {formatMessage(messages.refineSearch)}
            </ButtonWithDescription>
            <ProductFilter
              structure={structure}
              structureUpdated={structureUpdated}
              isShow={isShowFilters}
              accreditedLenderIds={accreditedLenderIds}
              submitAction={this.submitFilterValues}
              closeFilterAction={this.closeFilter}
            />
            <ComparePageNavigator
              showSortingDropdown={products.productList && !isSpinnerLoading}
              orderField={structure.orderField}
              setFilterOrderFieldAction={this.sortingProduct}
              backLabel={formatMessage(messages.back)}
              sortingOptions={PRODUCT_ORDER_OPTIONS}
              goBack={back}
            />
            {!isSpinnerLoading && products.productList && (
              <ProductList
                structure={structure}
                isLocked={isLocked}
                products={products.productList}
                onSelection={this.onSelectProduct}
                focusProductId={
                  products.productDetail && products.productDetail.id
                }
                sortingDescription={
                  findOption(PRODUCT_ORDER_OPTIONS, structure.orderField).name
                }
                orderField={structure.orderField}
                showMoreButton={
                  !isGroupingProducts(structure.selectedLenderIds) &&
                  // eslint-disable-next-line prettier/prettier
                hasMoreLenderProducts
                }
                isLoadingMore={isLoadingMoreProducts}
                onLoadMore={this.onLoadMoreProducts}
              />
            )}
            <RepaymentsExplanation />
          </ContentsWrapper>
        </Spinner>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  isSpinnerLoading: UISelectors.hasActiveSpinners(state),
  loanApplicationId: state.application.working.id,
  structure: structureSelectors.workingStructure(state),
  primaryStructure: structureSelectors.primaryStructure(state),
  products: productSelectors.productListMetaData(state),
  isLocked: getIsLocked(state),
  hasMoreLenderProducts: productSelectors.hasMoreLenderProducts(state),
  isLoadingMoreProducts: state.products.isLoadingMore,
  loanAmount: fundingSelectors.totalLoanRequired(state),
  accreditedLenderIds: goalLoanAppInfoSelectors.accreditedLenderIds(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestSortedProducts: productsActions.requestSortedProducts,
      requestProducts: productsActions.requestProducts,
      requestMoreProducts: productsActions.requestMoreProducts,
      updateWorkingStructure: structureActions.updateWorkingStructure,
      loadStructure: structureActions.loadStructure,
      saveStructure: structureActions.saveStructure,
      selectProduct: structureActions.selectProduct,
      goBack,
      push,
    },
    dispatch,
  );

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ComparePage),
);
