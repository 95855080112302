import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './SpinnerText.css';

export default class SpinnerText extends Component {
  static propTypes = {
    title: PropTypes.string,
    titleSequence: PropTypes.arrayOf(PropTypes.object),
    subtitle: PropTypes.string,
    caption: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      idx: 0,
    };
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  setTitleTimeout() {
    const { titleSequence } = this.props;
    const { idx } = this.state;

    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(this.setLoadingTitle, titleSequence[idx].timer);
  }

  setLoadingTitle = () => {
    const { titleSequence } = this.props;
    const { idx } = this.state;
    const loopIdx = titleSequence && titleSequence.findIndex((a) => a.loopHere);
    const newIdx = idx + 1 >= titleSequence.length ? loopIdx : idx + 1;
    this.setState(
      {
        idx: newIdx < 0 ? 0 : newIdx,
      },
      this.setTitleTimeout(),
    );
  };

  render() {
    const { title, subtitle, caption, titleSequence } = this.props;

    const { idx } = this.state;

    const loadingTitle =
      title ||
      (titleSequence && titleSequence[idx] && titleSequence[idx].message);

    if (titleSequence) {
      this.setTitleTimeout();
    }

    return (
      <div className={styles.description}>
        {(title || titleSequence) && (
          <h1 className={classNames(styles.title, styles.ellipsisAnimate)}>
            {loadingTitle}
          </h1>
        )}
        {subtitle && (
          <p className={styles.subtitle}>
            <strong>{subtitle}</strong>
          </p>
        )}
        {caption && <p className={styles.subtitle}>{caption}</p>}
      </div>
    );
  }
}
