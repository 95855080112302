export const OKTA_STATUSES = {
  SUCCESS: 'SUCCESS',
  MFA_ENROLL: 'MFA_ENROLL',
  MFA_CHALLENGE: 'MFA_CHALLENGE',
  MFA_ENROLL_ACTIVATE: 'MFA_ENROLL_ACTIVATE',
  MFA_ACTIVATE: 'MFA_ACTIVATE',
  MFA_REQUIRED: 'MFA_REQUIRED',
  PASSWORD_RESET: 'PASSWORD_RESET',
  LOCKED_OUT: 'LOCKED_OUT',
  ACTIVE: 'ACTIVE',
};
