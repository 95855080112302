/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import classNames from 'classnames/bind';

import { formatCurrency, formatRate } from 'lib/intlFormatters';
import { formatPercentage } from 'shared/lib/numbrero';

import ProductTable from './ProductTable';

import styles from './SavingTable.css';

const messages = defineMessages({
  perMonth: {
    id: 'SavingTable.perMonth',
    defaultMessage: 'Per month',
  },
  saveRate: {
    id: 'SavingTable.saveRate',
    defaultMessage: 'Save {rate}',
  },
  initial: {
    id: 'SavingTable.Initial',
    defaultMessage: 'Initial',
  },
  repayment: {
    id: 'SavingTable.repayment',
    defaultMessage: 'Repayment',
  },
  variableLifeTimeSaving: {
    id: 'SavingTable.variableLifeTimeSaving',
    defaultMessage: '{totalInterest} Lifetime Savings',
  },
  noInterestSaving: {
    id: 'SavingTable.noInterestSaving',
    defaultMessage: 'Your current rate is lower*',
  },
});

const renderRepaymentCell = (intl) => (product) => {
  const { formatMessage } = intl;
  return (
    <div className={classNames(styles.cell)}>
      <span>
        {formatCurrency(intl)(product.repayments.monthly.initial.amount)}
      </span>
      <span className={styles.footnote}>
        {formatMessage(messages.perMonth)}
      </span>
    </div>
  );
};

const renderInitialCell = (currentInterestRate, intl) => (product) => {
  const { formatMessage } = intl;
  const rateSaving = currentInterestRate - product.rates.initial.rate;

  return (
    <div className={classNames(styles.cell, styles.clickable)}>
      <span>{formatRate(intl)(product.rates.initial.rate)}</span>
      {product.saving > 0 && (
        <span className={styles.footnote}>
          {formatMessage(messages.saveRate, {
            rate: formatPercentage(rateSaving > 0 ? rateSaving : 0),
          })}
        </span>
      )}
    </div>
  );
};

const renderTopDealCell = (intl) => (product, productType) => {
  const totalSaving =
    product.saving > 0
      ? intl.formatMessage(messages.variableLifeTimeSaving, {
          totalInterest: formatCurrency(intl)(product.saving * 30 * 12),
        })
      : intl.formatMessage(messages.noInterestSaving);

  return (
    <div className={styles.cell}>
      <span>{intl.formatMessage(productType)}</span>
      <span
        className={classNames(styles.footnote, {
          [styles.noSaving]: product.saving <= 0,
        })}
      >
        {totalSaving}
      </span>
    </div>
  );
};

const SavingTable = ({
  intl,
  intl: { formatMessage },
  firstHeading,
  currentInterestRate,
  ...otherProps
}) => (
  <ProductTable
    headings={[
      firstHeading,
      formatMessage(messages.initial),
      formatMessage(messages.repayment),
    ]}
    cells={[
      renderTopDealCell(intl),
      renderInitialCell(currentInterestRate, intl),
      renderRepaymentCell(intl),
    ]}
    {...otherProps}
  />
);

SavingTable.propTypes = {
  intl: intlShape,
  firstHeading: PropTypes.string.isRequired,
  currentInterestRate: PropTypes.number.isRequired,
};

export default injectIntl(SavingTable);
