import React, { useState } from 'react';
import PropTypes from 'prop-types';
import locale from 'config/locale';
import { lenderLogoUrl, getLenderStyle } from 'lib/utils/imageUtils';

import styles from './LogoGrid.css';

const LogoGrid = ({ lenders }) => {
  const [showLogos, setShowLogos] = useState({});

  const renderLogo = (lender) => (
    <li
      key={lender}
      className={styles.item}
      style={getLenderStyle(lender, showLogos)}
    >
      <img
        className={styles.logo}
        src={lenderLogoUrl(lender, locale.data.countryCode)}
        alt={lender}
        onLoad={() => setShowLogos((s) => ({ ...s, [lender]: true }))}
      />
    </li>
  );

  return <ol className={styles.root}>{lenders.map(renderLogo)}</ol>;
};

LogoGrid.propTypes = {
  lenders: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default LogoGrid;
