import React, { Component } from 'react';

import Spinner from 'components/Spinner/Spinner';
import sanityCheck from 'hocs/sanityCheck';

const sanitiseProps = ({ history, match }) => ({
  accessId: history.location.query && history.location.query.MGACCESSID,
  status: match.params && match.params.status,
});

export const IFRAME_STATUS_COMPLETE = 'complete';
export const IFRAME_STATUS_ERROR = 'error';

export class BankConnectStatus extends Component {
  static isRequiredStructure = (props) => {
    const { accessId, status } = sanitiseProps(props);
    return (
      !!accessId &&
      (status === IFRAME_STATUS_COMPLETE || status === IFRAME_STATUS_ERROR)
    );
  };

  componentDidMount = () => {
    console.log('SANITISE PROPS', this.props);
    const { accessId, status } = sanitiseProps(this.props);
    window.parent.postMessage({ accessId, status }, '*');
  };

  render() {
    // eslint-disable-line class-methods-use-this
    /* MOGO iframe redirection page */
    return <Spinner loading />;
  }
}

export default sanityCheck(BankConnectStatus);
