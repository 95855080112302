/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

import { formatRate } from 'lib/intlFormatters';

import ProductTable from './ProductTable';

const messages = defineMessages({
  rate: {
    id: 'MaxBorrowTable.rate',
    defaultMessage: 'Rate',
  },
  comparison: {
    id: 'MaxBorrowTable.comparison',
    defaultMessage: 'Comparison',
  },
});

const renderRate = (intl) => (product) => (
  <span>{formatRate(intl)(product.rates.initial.rate)}</span>
);

const renderComparison = (intl) => (product) => (
  <span>{formatRate(intl)(product.rates.comparison.rate)}</span>
);

const MaxBorrowTable = ({
  intl,
  intl: { formatMessage },
  firstHeading,
  ...otherProps
}) => (
  <ProductTable
    headings={[
      firstHeading,
      formatMessage(messages.rate),
      formatMessage(messages.comparison),
    ]}
    cells={[
      (product, productType) => formatMessage(productType),
      renderRate(intl),
      renderComparison(intl),
    ]}
    {...otherProps}
  />
);

MaxBorrowTable.propTypes = {
  intl: intlShape,
  firstHeading: PropTypes.string.isRequired,
};

export default injectIntl(MaxBorrowTable);
