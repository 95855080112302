import React from 'react';
import PropTypes from 'prop-types';

import Input from 'components/Input/Input';

import { mobileFormatter } from 'lib/formatters';

const MobileInput = ({ action, value, ...otherProps }) => (
  <Input
    action={action}
    value={value}
    formatter={mobileFormatter}
    maxLength={15}
    pattern='\\d*'
    type='tel'
    {...otherProps}
  />
);

MobileInput.propTypes = {
  action: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default MobileInput;
