const draftApplication = {
  title: 'Profile saved',
  subtitle: (loanApplicationId) => `Profile ID ${loanApplicationId}`,
};

const preapplicationMessages = {
  title: 'Pre-approval support & advice',
  subtitle: 'Understanding your options',
  body: [
    'You’ve told us about your goals and financial position. We’ve saved everything, you can view or update your profile at any time.',
    'Don’t worry if you’re not ready to go right now - we understand it’s a long process and we’ll be there if you need us.',
    'If you haven’t already spoken to one of our team, we’ll be in touch soon.',
    'We’ll review your situation, talk through your options and if you’re ready to go - start to get things moving. If you have any questions don’t hesitate to ask. Speaking to someone who gets it can really make a difference.',
  ],
};

const lodgingApplicationMessages = {
  title: 'Lodging your application',
  subtitle: 'Submitting to your lender',
  body: [
    'When you’re ready to proceed, the next step is to prepare and lodge your full application. Lenders need to see documents that provide proof for the information you’ve provided, so now is a good time to start gathering documents like:',
  ],
  list: [
    'Identification (driver’s licence, birth certificate)',
    'Income (recent payslips, rental agreement)',
    'Assets (bank account statements, proof of ownership)',
    'Liabilities (credit card statement, loan statement)',
  ],
};

const preliminaryApprovalMessages = {
  title: 'Getting preliminary approval',
  subtitle: 'Conditional or pre-approval from your lender',
  body: [
    'This represents the initial thumbs up from your lender.',
    'It gives you preliminary approval to borrow the funds you need based on the information you’ve supplied in your application.',
    'The next step is usually to submit supporting documentation to validate everything. Once your application has been lodged we’ll keep you up to date with its progress and work with you to sort out all the paperwork required.',
  ],
};

const finalApprovalMessages = {
  title: 'Getting final approval',
  subtitle: 'Unconditional approval from your lender',
  body: [
    'This represents the final thumbs up from your lender.',
    'Exciting times. Most of the hard work is over. Once this stage is complete your lender has committed to providing you with the money you need. Well done.',
    'Once complete you’re basically good to go, and simply waiting for the purchase settlement date and release of funds.',
  ],
};

const receivingMoneyMessages = {
  title: 'Receiving the money',
  subtitle: 'Final settlement and transfer of funds',
  body: [
    'Once this stage is complete you’re done. It means you have received all the funds from your lender and you’ve started the process of paying back the loan.',
    'You’ve got the money. It’s time to celebrate. Congratulations!',
  ],
};

const protectAndGrowMessages = {
  title: 'Protect and grow',
  subtitle: 'Getting smarter with your money',
  body: [
    'This is the time to plan your next steps and protect you and your family in the future. We’ll stay in touch and help you get smarter with your money.',
  ],
};

export const progressItems = [
  {
    icon: 'sl-custom-plane-paper-1',
    title: draftApplication.title,
    subtitle: draftApplication.subtitle,
  },
  {
    icon: 'sl-custom-user-search-2',
    title: preapplicationMessages.title,
    subtitle: preapplicationMessages.subtitle,
    body: preapplicationMessages.body,
    list: preapplicationMessages.list,
  },
  {
    icon: 'sl-custom-mail-box-3',
    title: lodgingApplicationMessages.title,
    subtitle: lodgingApplicationMessages.subtitle,
    body: lodgingApplicationMessages.body,
    list: lodgingApplicationMessages.list,
  },
  {
    icon: 'sl-custom-hand-like-1',
    title: preliminaryApprovalMessages.title,
    subtitle: preliminaryApprovalMessages.subtitle,
    body: preliminaryApprovalMessages.body,
    list: preliminaryApprovalMessages.list,
  },
  {
    icon: 'sl-custom-hand-like-1',
    title: finalApprovalMessages.title,
    subtitle: finalApprovalMessages.subtitle,
    body: finalApprovalMessages.body,
    list: finalApprovalMessages.list,
  },
  {
    icon: 'sl-custom-dollar-bag',
    title: receivingMoneyMessages.title,
    subtitle: receivingMoneyMessages.subtitle,
    body: receivingMoneyMessages.body,
    list: receivingMoneyMessages.list,
  },
  {
    icon: 'sl-custom-hand-eco',
    title: protectAndGrowMessages.title,
    subtitle: protectAndGrowMessages.subtitle,
    body: protectAndGrowMessages.body,
    list: protectAndGrowMessages.list,
  },
];

export const PROGRESS_STATUS_COMPLETE = 'completed';
export const PROGRESS_STATUS_IN_PROGRESS = 'inProgress';
export const PROGRESS_STATUS_NEXT_STEP = 'nextStep';
export const PROGRESS_STATUS_NOT_STARTED_YET = 'notStartedYet';
