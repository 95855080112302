import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl } from 'react-intl';
import _ from 'lodash';

import locale from 'config/locale';

import scenarioActions from 'actions/scenarioActions';
import UIActions from 'actions/UIActions';
import otherActions from 'actions/otherActions';

import { formatCurrency } from 'lib/intlFormatters';
import { generateDisclaimer } from 'lib/disclaimerHelper';
import { loanParams } from 'lib/loanParamsBuilder';
import { toForkOrSignup, TO_RENT_PROPERTY } from 'lib/pathHelper';

import {
  INITIAL_SCENARIO_STATE,
  PROSPECTIVE_PROPERTY_ID,
  DEFAULT_EXISTING_PROPERTY_ID,
} from 'shared/constants/defaults';

import { isRefinanceOrRenovate } from 'shared/lib/checkLoanPurpose';
import {
  getDefaultExistingProperty,
  getProspectiveProperty,
} from 'shared/lib/propertyHelper';

import {
  POSITIVE_CHART_SPECTRUM,
  SUMMARY_SECTION_COLOUR,
} from 'constants/colours';
import { AU_UNIQUE_ITEM } from 'constants/reportItemTitles';

import { LOAN_PURPOSE_INVESTMENT } from 'shared/constants/loanPurposes';
import { BUYER_SCENARIO_FHB } from 'shared/constants/loanScenarios';

import generatePurchaseCostParams from 'lib/purchaseCostParamsBuilder';

import sanityCheck from 'hocs/sanityCheck';

import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import Spinner from 'components/Spinner/Spinner';
import View from 'components/View/View';
import ButtonNext from 'components/ButtonNext/ButtonNext';
import ReportContent from 'components/ReportContent/ReportContent';

import * as UISelectors from 'selectors/UISelectors';

import {
  hydrateMetadata,
  loanReportContributions,
  loanReportPurchaseCosts,
} from 'selectors/reportSelectors';

import { LVRMetadata } from 'constants/reportMetadata';

import {
  loanAmount as getLoanAmount,
  lmi as getLmi,
} from 'selectors/scenarioSelectors';

class LoanReport extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    isSpinnerLoading: PropTypes.bool,
    scenario: PropTypes.object.isRequired,
    loanAmount: PropTypes.number,
    lmi: PropTypes.number,
    purchaseCosts: PropTypes.arrayOf(PropTypes.object),
    contributions: PropTypes.arrayOf(PropTypes.object),
    LVRSection: PropTypes.object,
    requestLowestRate: PropTypes.func.isRequired,
    requestQuickLmi: PropTypes.func.isRequired,
    requestPurchaseCost: PropTypes.func.isRequired,
    goToPathWithAnimation: PropTypes.func.isRequired,
    children: PropTypes.node,
  };

  static isRequiredStructure = (props) =>
    !_.isEqual(props.scenario, INITIAL_SCENARIO_STATE);

  componentDidMount() {
    const { scenario, loanAmount } = this.props;
    window.scrollTo(0, 0);
    this.requestLmi(loanAmount, scenario);
  }

  static getDerivedStateFromProps(props) {
    const { requestPurchaseCost, scenario } = props;
    const oldProperty = getProspectiveProperty(scenario) || {};
    const newProperty = getProspectiveProperty(props.scenario) || {};
    if (oldProperty.value !== newProperty.value) {
      requestPurchaseCost({
        params: generatePurchaseCostParams(props.scenario),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loanAmount !== this.props.loanAmount) {
      this.requestLmi(this.props.loanAmount, this.props.scenario);
    }
  }

  requestLmi(loanAmount, scenario) {
    const { requestQuickLmi } = this.props;
    if (locale.isAU) {
      const newProperty = getProspectiveProperty(scenario) || {};
      requestQuickLmi({
        loanAmount,
        propertyValue: newProperty.value,
        isFirstHomeBuyer: scenario.name === BUYER_SCENARIO_FHB,
        state: newProperty.state || undefined,
        ownerOccupied: !!newProperty.ownerOccupied,
      });
    }
  }

  nextStep = () => {
    const { scenario, requestLowestRate, goToPathWithAnimation } = this.props;
    if (scenario.loanPurpose === LOAN_PURPOSE_INVESTMENT) {
      goToPathWithAnimation(TO_RENT_PROPERTY);
    } else {
      if (locale.isAU) {
        requestLowestRate({
          property: isRefinanceOrRenovate(scenario.loanPurpose)
            ? DEFAULT_EXISTING_PROPERTY_ID
            : PROSPECTIVE_PROPERTY_ID,
          loanParams: loanParams(scenario),
        });
      }
      goToPathWithAnimation(toForkOrSignup());
    }
  };

  title() {
    const { loanAmount, lmi } = this.props;
    return loanAmount <= 0
      ? 'You don’t need a loan!? Re-check your numbers.'
      : `You’ll need to borrow about ${formatCurrency(this.props.intl)(
          loanAmount + lmi,
        )}.`;
  }

  renderReport = () => {
    const {
      loanAmount,
      lmi,
      contributions,
      purchaseCosts,
      LVRSection,
    } = this.props;
    if (!locale.isAU) {
      _.remove(purchaseCosts, (p) => AU_UNIQUE_ITEM.includes(p.name));
      _.remove(contributions, (p) => AU_UNIQUE_ITEM.includes(p.name));
    }
    const data = [
      {
        label: 'Purchase Costs',
        items: purchaseCosts,
        isChartTotal: true,
        colour: SUMMARY_SECTION_COLOUR,
        isAccordion: false,
      },
      {
        label: 'Contributions',
        items: contributions,
        colour: POSITIVE_CHART_SPECTRUM[1],
        isAccordion: false,
      },
      {
        label: 'Loan Required',
        value: loanAmount + lmi,
        colour: POSITIVE_CHART_SPECTRUM[0],
        isAccordion: false,
        items: [LVRSection],
      },
    ];

    return (
      <ReportContent id='loan' data={data} theme='borderReport'>
        <ButtonNext id='next' onClick={this.nextStep} />
      </ReportContent>
    );
  };

  render() {
    const { scenario, isSpinnerLoading } = this.props;

    const existingProperty = getDefaultExistingProperty(scenario);
    const disclaimer = generateDisclaimer(existingProperty);

    return (
      <View footerDisclaimer={disclaimer} inverseHeader>
        <ContentsWrapper id='loanReport' title={this.title()} inverseHeader>
          <Spinner loading={isSpinnerLoading} fillParent>
            {this.renderReport()}
          </Spinner>
          {this.props.children}
        </ContentsWrapper>
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  scenario: state.scenario,
  loanAmount: getLoanAmount(state),
  lmi: getLmi(state),
  purchaseCosts: loanReportPurchaseCosts(state, ownProps),
  contributions: loanReportContributions(state, ownProps),
  LVRSection: hydrateMetadata(LVRMetadata)(state, ownProps),
  isSpinnerLoading: UISelectors.hasActiveSpinners(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestQuickLmi: otherActions.requestQuickLmi,
      requestPurchaseCost: scenarioActions.requestPurchaseCost,
      requestLowestRate: scenarioActions.requestLowestRate,
      goToPathWithAnimation: UIActions.goToPathWithAnimation,
    },
    dispatch,
  );

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(sanityCheck(LoanReport)),
);
