/* eslint-disable sonarjs/no-duplicate-string */
export const campaigns = {
  'compare-fixed-loan-rates': {
    title: 'Compare today’s lowest fixed home loan rates.',
  },
  'compare-loan-rates': {
    title: 'Compare today’s lowest home loans.',
  },
  'compare-mortgage-rates': {
    title: 'Compare today’s lowest mortgage rates.',
  },
  'compare-variable-loan-rates': {
    title: 'Compare today’s lowest variable home loans.',
  },
  'home-loan-rate-comparison': {
    title: 'Compare today’s lowest home loans.',
  },
  'low-rate-home-loans': {
    title: 'Compare today’s lowest home loans.',
  },
  'compare-mortgage-repayments': {
    title: 'Compare today’s lowest mortgage rates.',
  },
  'home-loan-comparison': {
    title: 'Compare today’s lowest home loans.',
  },
  'loan-repayment-comparisons': {
    title: 'Compare today’s lowest home loans.',
  },
  brokers: {
    title: 'We have a local broker ready to help you now.',
  },
};

export const triggers = {
  share: 'Online Fact Find',
};
