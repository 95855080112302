import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import scenarioActions from 'actions/scenarioActions';
import UIActions from 'actions/UIActions';

import { getProspectiveProperty } from 'shared/lib/propertyHelper';
import generatePurchaseCostParams from 'lib/purchaseCostParamsBuilder';

import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';
import sanityCheck from 'hocs/sanityCheck';

import { LOAN_REPORT_PATH } from 'shared/constants/paths';
import { FHOG_OPTIONS } from 'constants/options';
import { REQUIRED } from 'constants/validators';
import { PROSPECTIVE_PROPERTY_ID } from 'shared/constants/defaults';

import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import Question from 'components/Question/Question';
import View from 'components/View/View';
import ButtonList from 'components/ButtonList/ButtonList';

class FirstHomeOwnersGrant extends React.Component {
  static propTypes = {
    scenario: PropTypes.object.isRequired,
    setPropertyFhogEligibility: PropTypes.func.isRequired,
    requestPurchaseCost: PropTypes.func.isRequired,
    setNextPath: PropTypes.func.isRequired,
    startAnimation: PropTypes.func.isRequired,
    ...manageQuestionsPropTypes,
  };

  static questionsToAsk = () => [
    { id: 'FHOGEligibility', branch: 1, validation: [REQUIRED] },
  ];

  static revealMethod = 'steps';
  static revealOverBranch = false;

  static isRequiredStructure = (props) =>
    !!getProspectiveProperty(props.scenario);

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  fhogAction = (value) => {
    const {
      scenario,
      setPropertyFhogEligibility,
      setNextPath,
      requestPurchaseCost,
      startAnimation,
    } = this.props;
    setPropertyFhogEligibility(value);
    requestPurchaseCost({
      params: {
        ...generatePurchaseCostParams(scenario),
        firstHomeBuyer: value === 'true',
      },
    });
    setNextPath(LOAN_REPORT_PATH);
    startAnimation(['/great', '/calculator']);
  };

  render() {
    const { questions, setCurrentQuestionTo } = this.props;

    const hint =
      '<div>Generally speaking, to be eligible you need to: </div><ul><li>Be purchasing a newly built home, or buying vacant land to build a home on.</li><li>Have never previously owned residential property in Australia.</li><li>Be an Australian citizen or permanent resident.</li></ul>';

    return (
      <View>
        <ContentsWrapper
          id='firstHomeOwnersGrant'
          title='Are you eligible for the first home owners grant?'
          onFocusLost={setCurrentQuestionTo(undefined)}
        >
          <Question
            {...questions.FHOGEligibility}
            hint={hint}
            direction='column'
            className='alignLeft'
            showError={false}
          >
            <ButtonList action={this.fhogAction} items={FHOG_OPTIONS} />
          </Question>
        </ContentsWrapper>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  scenario: state.scenario,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPropertyFhogEligibility: scenarioActions.setPropertyFhogEligibility(
        PROSPECTIVE_PROPERTY_ID,
      ),
      requestPurchaseCost: scenarioActions.requestPurchaseCost,
      setNextPath: UIActions.setNextPath,
      startAnimation: UIActions.startAnimationSequence,
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  sanityCheck,
  manageQuestions,
)(
  FirstHomeOwnersGrant,
  ({ scenario }) => scenario.properties.prospective || {},
);
