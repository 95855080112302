import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from '@loan_market/react-router-redux-multi';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

import UIActions from 'actions/UIActions';
import productsActions from 'actions/productsActions';
import structureActions from 'actions/structureActions';
import otherActions from 'actions/otherActions';

import * as clientSelectors from 'selectors/clientSelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';
import * as eligibility from 'selectors/eligibilitySelectors';
import * as productSelectors from 'selectors/productSelectors';
import * as contactSelectors from 'selectors/contactSelectors';
import * as incomeSelectors from 'selectors/incomeSelectors';
import * as expenseSelectors from 'selectors/expenseSelectors';
import * as UISelectors from 'selectors/UISelectors';

import { DASHBOARD_BASE_PATH } from 'shared/constants/paths';

import View from 'components/View/View';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import Spinner from 'components/Spinner/Spinner';
import LenderRecord from 'components/LenderRecord/LenderRecord';
import MaxBorrowTable from 'components/LenderRecord/MaxBorrowTable';
import ButtonNext from 'components/ButtonNext/ButtonNext';

import commonMessages from 'constants/commonMessages';
import { householdIsCouple } from 'lib/utils/formUtils';
import { gotoCompareWithStructure } from 'lib/compareHelper';

const messages = defineMessages({
  title: {
    id: 'BorrowingCapacity.title',
    defaultMessage:
      'Great news! You might be able to borrow up to { maximumLoanAmount, number, currency}',
  },
  titleNegativeNews: {
    id: 'BorrowingCapacity.titleNegativeNew',
    defaultMessage:
      'You might be able to borrow up to { maximumLoanAmount, number, currency}',
  },
  legendLender: {
    id: 'BorrowingCapacity.legendLender',
    defaultMessage: 'Lender',
  },
  legendMaxBorrow: {
    id: 'BorrowingCapacity.legendMaxBorrow',
    defaultMessage: 'Max Borrow',
  },
  householdShape: {
    id: 'BorrowingCapacity.householdShape',
    defaultMessage: `Based on a {isCouple, select, true {Couple} false {Single}}
                    with {numberOfDependents, plural,
                    =0 {no Children}
                    one {1 Child}
                    =5 {{numberOfDependents}+ Children}
                    other {{numberOfDependents} Children}}`,
  },
  income: {
    id: 'BorrowingCapacity.incomePm',
    defaultMessage: 'Income {income, number, currency} p.m',
  },
  expenses: {
    id: 'BorrowingCapacity.expenses',
    defaultMessage: 'Expenses {expenses, number, currency} p.m',
  },
  rate: {
    id: 'BorrowingCapacity.rate',
    defaultMessage: 'Rate',
  },
  comparison: {
    id: 'BorrowingCapacity.comparison',
    defaultMessage: 'Comparison',
  },
  maxBorrowHeading: {
    id: 'BorrowingCapacity.maxBorrowHeading',
    defaultMessage: 'Maximum Borrowing Capacity',
  },
  totalBorrow: {
    id: 'BorrowingCapacity.totalBorrow',
    defaultMessage: 'TOTAL BORROW',
  },
  initialAssessment: {
    id: 'BorrowingCapacity.initialAssessment',
    defaultMessage: 'Initial Assessment',
  },
});

export class BorrowingCapacity extends Component {
  static propTypes = {
    requestMaxBorrow: PropTypes.func.isRequired,
    results: PropTypes.arrayOf(PropTypes.object),
    products: PropTypes.object.isRequired,
    numberOfDependents: PropTypes.number.isRequired,
    totalMonthlyExpenses: PropTypes.number.isRequired,
    totalMonthlyIncomes: PropTypes.number.isRequired,
    householdShape: PropTypes.string,
    isSpinnerLoading: PropTypes.bool.isRequired,
    requestFeaturedProducts: PropTypes.func.isRequired,
    startAnimation: PropTypes.func.isRequired,
    setNextPath: PropTypes.func.isRequired,
    goTo: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    maximumLoanAmount: PropTypes.number.isRequired,
    updateWorkingStructure: PropTypes.func.isRequired,
    isSuccess: PropTypes.bool,
    applicationId: PropTypes.number,
    contactId: PropTypes.number,
  };

  static defaultProps = {
    isSuccess: true,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.props.results) {
      const {
        requestMaxBorrow,
        applicationId: loanAppId,
        contactId,
      } = this.props;
      requestMaxBorrow({ isGoalSetter: false, contactId, loanAppId });
    }
  }

  getHouseholdInfo() {
    const {
      intl: { formatMessage },
      householdShape,
      numberOfDependents,
    } = this.props;

    return formatMessage(messages.householdShape, {
      isCouple: householdIsCouple(householdShape),
      numberOfDependents,
    });
  }

  getIncomeAndExpenseInfo() {
    const {
      intl: { formatMessage },
      totalMonthlyExpenses,
      totalMonthlyIncomes,
    } = this.props;
    const incomes = formatMessage(messages.income, {
      income: totalMonthlyIncomes,
    });
    const expenses = formatMessage(messages.expenses, {
      expenses: totalMonthlyExpenses,
    });
    return `${incomes} | ${expenses}`;
  }

  gotoLendersProducts = (lenderId) => () => {
    const { goTo, updateWorkingStructure } = this.props;

    gotoCompareWithStructure({
      lenderId,
      updateWorkingStructure,
      goTo,
    });
  };

  nextStep = () => {
    const { startAnimation, setNextPath } = this.props;
    setNextPath(DASHBOARD_BASE_PATH);
    startAnimation(['/nice-work']);
  };

  buildTitle = () => {
    const {
      intl: { formatMessage },
      maximumLoanAmount,
      isSuccess,
    } = this.props;
    let title = null;

    if (maximumLoanAmount) {
      const postFix = isSuccess ? '' : 'NegativeNews';
      title = formatMessage(messages[`title${postFix}`], { maximumLoanAmount });
    }

    return title;
  };

  renderLender = (lender) => {
    const {
      products,
      maximumLoanAmount,
      requestFeaturedProducts,
      updateWorkingStructure,
      intl: { formatMessage },
    } = this.props;
    const lendersProducts = products[lender.lenderCode];
    return (
      lender.bank && (
        <LenderRecord
          key={lender.lenderCode}
          updateWorkingStructure={updateWorkingStructure}
          lender={lender}
          products={lendersProducts}
          value={lender.maximumLoanAmount}
          maximumValue={maximumLoanAmount}
          legend={formatMessage(messages.legendMaxBorrow)}
          requestFeaturedProducts={requestFeaturedProducts}
          primaryAction={this.gotoLendersProducts(lender.bank.lenderId)}
          productTable={MaxBorrowTable}
          infoSectionProps={{
            heading: formatMessage(messages.maxBorrowHeading),
            headingInfoItems: [
              this.getHouseholdInfo(),
              this.getIncomeAndExpenseInfo(),
            ],
            valueHeading: formatMessage(messages.initialAssessment),
            valueSubtitle: formatMessage(messages.totalBorrow),
          }}
        />
      )
    );
  };

  render() {
    const {
      results,
      isSpinnerLoading,
      intl: { formatMessage },
    } = this.props;

    return (
      <View inverseHeader>
        <Spinner loading={isSpinnerLoading}>
          <ContentsWrapper
            id='borrowingCapacity'
            title={this.buildTitle()}
            animateHeading={false}
            inverseHeader
          >
            {results && results.map(this.renderLender)}
            {!isSpinnerLoading && (
              <ButtonNext onClick={this.nextStep}>
                {formatMessage(commonMessages.next)}
              </ButtonNext>
            )}
          </ContentsWrapper>
        </Spinner>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const dependents = contactSelectors.primaryContactsDependents(state);

  return {
    isSpinnerLoading: UISelectors.hasActiveSpinners(state),
    results: eligibility.maxBorrowResultsSorted(state),
    maximumLoanAmount: eligibility.maximumLoanAmount(state),
    products: productSelectors.getFeaturedProducts(state),
    householdShape: contactSelectors.householdShape(state),
    numberOfDependents: dependents ? dependents.length : 0,
    totalMonthlyIncomes: incomeSelectors.totalIncomesMonthly(state),
    totalMonthlyExpenses: expenseSelectors.totalMonthlyExpenses(state),
    applicationId: applicationSelectors.getApplicationId(state),
    contactId: clientSelectors.primaryApplicantContactId(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestMaxBorrow: otherActions.requestMaxBorrow,
      requestFeaturedProducts: productsActions.requestFeaturedProducts,
      requestProducts: productsActions.requestProducts,
      startAnimation: UIActions.startAnimationSequence,
      updateWorkingStructure: structureActions.updateWorkingStructure,
      setNextPath: UIActions.setNextPath,
      goTo: push,
    },
    dispatch,
  );

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(BorrowingCapacity),
);
