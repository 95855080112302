import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import locale from 'config/locale';

import { lenderPdpBannerUrl } from 'lib/utils/imageUtils';

import styles from './ComparePageBanner.css';

const ComparePageBanner = ({ lenderObject, title }) => {
  const bannerUrl = lenderPdpBannerUrl(
    lenderObject.lenderId,
    locale.data.countryCode,
  );
  return (
    <div>
      <div className={classNames(styles.banner)}>
        <img src={bannerUrl} alt={lenderObject.lenderName} />
      </div>
      <p
        className={classNames(
          styles.bannerFooter,
          styles[`lenderBackground${lenderObject.key}`],
        )}
      >
        {title}
      </p>
    </div>
  );
};

ComparePageBanner.propTypes = {
  lenderObject: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default ComparePageBanner;
