import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import _ from 'lodash';

import Button from 'components/Button/Button';

import SpinningWheel from 'assets/icons/spinning-wheel.svg';
import Rotation from 'components/animations/Rotation';
import SVGInline from 'react-svg-inline';

import { lenderProductTypes } from 'lib/lenderHelper';

import styles from './ProductTable.css';

const renderNoProducts = (lenderName) => (
  <div className={styles.noProducts}>
    <p>
      It looks like {lenderName} does not have any products that match your
      particular needs. There are a few reasons this may happen:
    </p>
    <p>- The amount you’re trying to borrow is too big or small.</p>
    <p>
      - This lender may not have products suited to your situation (e.g. lending
      for investment).
    </p>
    <p>
      If you’re really keen on borrowing from {lenderName}, we can try and work
      with them to accommodate your needs. For the moment try selecting another
      lender to see what they can do for you.
    </p>
  </div>
);

class ProductTable extends Component {
  static propTypes = {
    products: PropTypes.object,
    hasNoProducts: PropTypes.bool,
    lenderName: PropTypes.string,
    headings: PropTypes.arrayOf(PropTypes.string).isRequired,
    cells: PropTypes.arrayOf(PropTypes.func).isRequired,
    handleSelection: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonStyleKey: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showButtonRow: -1,
    };
  }

  handleRowClick = (product) => (e) => {
    e.preventDefault();
    this.props.handleSelection(product);
  };

  handleShowButton = (showButtonRow) => () => {
    this.setState({
      showButtonRow,
    });
  };

  renderHeader(content) {
    const { headings } = this.props;

    return (
      <div className={styles.productTable}>
        <div className={styles.headers}>
          <div className={styles.productName}>{headings[0]}</div>
          <div className={styles.rateAndComparison}>
            <span>{headings[1]}</span>
            <span>{headings[2]}</span>
          </div>
        </div>
        <div className={styles.tableContent}>
          {content || (
            <div className={styles.spinner}>
              <Rotation width='2rem' height='2rem'>
                <SVGInline svg={SpinningWheel} />
              </Rotation>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderTableContent() {
    const { products, cells, buttonText, buttonStyleKey } = this.props;
    const { showButtonRow } = this.state;

    return _.keys(lenderProductTypes).map(
      (key, i) =>
        products[key] && (
          <div
            className={styles.tableRow}
            key={key}
            onMouseEnter={this.handleShowButton(i)}
            onMouseLeave={this.handleShowButton(-1)}
            onClick={this.handleRowClick(products[key])}
          >
            <div className={styles.productName}>
              {cells[0](products[key], lenderProductTypes[key])}
            </div>
            {i === showButtonRow ? (
              <div className={styles.rateAndComparison}>
                <Button
                  onClick={this.handleRowClick(products[key])}
                  className={classNames(
                    styles[`button${buttonStyleKey}`],
                    styles.detailsButton,
                  )}
                >
                  {buttonText}
                </Button>
              </div>
            ) : (
              <div className={styles.rateAndComparison}>
                {cells[1](products[key])}
                {cells[2](products[key])}
              </div>
            )}
          </div>
        ),
    );
  }

  render() {
    const { products, hasNoProducts, lenderName } = this.props;
    let content;

    if (!products) {
      content = this.renderHeader();
    } else if (hasNoProducts) {
      content = renderNoProducts(lenderName);
    } else {
      content = this.renderHeader(this.renderTableContent());
    }

    return content;
  }
}

export default ProductTable;
