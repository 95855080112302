import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import locale from 'config/locale';
import classNames from 'classnames/bind';

import scenarioActions from 'actions/scenarioActions';

import { lmLenderKeys } from 'constants/lmLenders';
import loanPurposes from 'constants/loanPurposesUI';
import { campaigns } from 'constants/campaigns';

import View from 'components/View/View';
import Question from 'components/Question/Question';
import LogoGrid from 'components/LogoGrid/LogoGrid';
import ButtonList from 'components/ButtonList/ButtonList';

import LazyLoad from 'react-lazyload';
import Button from 'components/Button/Button';
import { GET_STARTED_PATH } from 'shared/constants/paths';

const messages = defineMessages({
  compareTitle: {
    id: 'PropertyGoals.compareTitle',
    defaultMessage: 'Compare.',
  },
  compareButtonText: {
    id: 'PropertyGoals.compareButtonText',
    defaultMessage: 'Compare home loans',
  },
  negotiateTitle: {
    id: 'PropertyGoals.negotiateTitle',
    defaultMessage: 'Negotiate.',
  },
  negotiateDescription: {
    id: 'PropertyGoals.negotiateDescription',
    defaultMessage:
      'We’ve got your back. Get access to exclusive home loan offers and we’ll help you negotiate a competitive deal. Talking to a person who gets it really makes a difference.',
  },
  negotiateButtonText: {
    id: 'PropertyGoals.negotiateButtonText',
    defaultMessage: 'Unlock exclusive offers',
  },
  saveTitle: {
    id: 'PropertyGoals.saveTitle',
    defaultMessage: 'Save.',
  },
  saveDescription: {
    id: 'PropertyGoals.saveDescription',
    defaultMessage:
      'We look to save thousands in interest payments and fees by finding the right loan and repayment structure for you.',
  },
  saveButtonText: {
    id: 'PropertyGoals.saveButtonText',
    defaultMessage: 'Find the right loan',
  },
  ratingTitle: {
    id: 'PropertyGoals.ratingTitle',
    defaultMessage: '4.8 out of 5 stars.',
  },
  ratingDescription: {
    id: 'PropertyGoals.ratingDescription',
    defaultMessage: 'Over 95% of our customers recommend us on Product Review.',
  },
  stopScrollingTitle: {
    id: 'PropertyGoals.stopScrollingTitle',
    defaultMessage: 'Loan Market.',
  },
  stopScrollingDescription: {
    id: 'PropertyGoals.stopScrollingDescription',
    defaultMessage: 'Home Loans Made Simple',
  },
  stopScrollingButtonText: {
    id: 'PropertyGoals.stopScrollingButtonText',
    defaultMessage: 'Stop scrolling – Get started',
  },
});

export class PropertyGoals extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    setLoanPurpose: PropTypes.func.isRequired,
    advisorOrg: PropTypes.object,
    history: PropTypes.object,
  };

  static defaultProps = {
    advisorOrg: {},
  };

  campaign() {
    const {
      location: { query },
    } = this.props.history;
    return query && campaigns[query.campaign];
  }

  title() {
    const { intl } = this.props;
    const campaignTitle = this.campaignTitle();
    return campaignTitle || intl.formatMessage(messages.title);
  }

  campaignTitle() {
    const {
      location: { query },
    } = this.props.history;
    let title = query.title && decodeURI(query.title).replace(/(_)/g, ' ');
    if (!title) {
      const campaign = this.campaign();
      title = campaign && campaign.title;
    }
    if (title) {
      const lastChar = title.charAt(title.length - 1);
      return !['.', '?', '!'].includes(lastChar) ? `${title}.` : title;
    }
    return undefined;
  }

  renderStandardBtn = (icon, text) => {
    return (
      <Button
        url={GET_STARTED_PATH}
        theme='big bigIcon homepage'
        className='brandColor__button'
        icon={icon}
      >
        <span>{text}</span>
      </Button>
    );
  };

  renderQuestionsSection = () => {
    const { intl, setLoanPurpose } = this.props;
    return (
      <section className={classNames('brandColor__bgOverride')}>
        <Question id='loanPurpose' animated forceFocus showLabel={false}>
          <ButtonList
            action={setLoanPurpose}
            items={loanPurposes(intl.formatMessage)()}
            animated
            theme='tile'
          />
        </Question>
      </section>
    );
  };

  renderStopScrollTitleAndDescription = () => {
    const {
      advisorOrg: { isOwnBrand, tradingName },
      intl,
    } = this.props;

    if (isOwnBrand) {
      return <h1>{tradingName}</h1>;
    }

    return (
      <>
        <h1>{intl.formatMessage(messages.stopScrollingTitle)}</h1>
        <p>{intl.formatMessage(messages.stopScrollingDescription)}</p>
      </>
    );
  };

  render() {
    const {
      advisorOrg: { isOwnBrand },
      intl,
    } = this.props;

    return (
      <View theme='homepage'>
        <section>
          <Button url={GET_STARTED_PATH} theme='homepageCrazy'>
            <h1>
              Loans made <strong>simple</strong>.
            </h1>
            <p>{this.campaignTitle()}</p>
          </Button>
        </section>
        {this.renderQuestionsSection()}

        <section>
          <h1>{intl.formatMessage(messages.compareTitle)}</h1>
          <p>
            {locale.isAU
              ? 'Search Australia’s biggest banks plus a range of over thirty specialist lenders.'
              : 'Search New Zealand’s biggest banks plus a range of specialist lenders.'}
          </p>
          <LogoGrid lenders={lmLenderKeys(locale.data.countryCode)} />
          {this.renderStandardBtn(
            'sl-custom-magnifier',
            intl.formatMessage(messages.compareButtonText),
          )}
        </section>

        <section>
          <h1>{intl.formatMessage(messages.negotiateTitle)}</h1>
          <p>{intl.formatMessage(messages.negotiateDescription)}</p>
          {this.renderStandardBtn(
            'sl-custom-hand-like-1',
            intl.formatMessage(messages.negotiateButtonText),
          )}
        </section>

        <section>
          <h1>{intl.formatMessage(messages.saveTitle)}</h1>
          <p>{intl.formatMessage(messages.saveDescription)}</p>
          {this.renderStandardBtn(
            'sl-custom-piggy-bank',
            intl.formatMessage(messages.saveButtonText),
          )}
        </section>
        {locale.isAU && !isOwnBrand && (
          <section>
            <h1>{intl.formatMessage(messages.ratingTitle)}</h1>
            <p>{intl.formatMessage(messages.ratingDescription)}</p>
            <LazyLoad height={785} once offset={500}>
              <iframe
                src='https://www.productreview.com.au/review-widget/v1/reviews?idFrom=v1&itemId=211913&num_reviews=6'
                frameBorder='0'
              />
            </LazyLoad>
          </section>
        )}
        <section>
          {this.renderStopScrollTitleAndDescription()}
          {this.renderStandardBtn(
            'sl-custom-plane-paper-1',
            intl.formatMessage(messages.stopScrollingButtonText),
          )}
        </section>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  advisorOrg: state.advisorOrg,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLoanPurpose: scenarioActions.setLoanPurpose,
    },
    dispatch,
  );

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(PropertyGoals),
);
