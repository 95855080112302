import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import View from 'components/View/View';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import Spinner from 'components/Spinner/Spinner';

import EmailSvg from 'assets/icons/email_sent.svg';

import scenarioActions from 'actions/scenarioActions';

const EmailVerification = (props) => {
  const { verifyEmailAddress, location } = props;
  const { query } = location || {};
  const { token } = query || {};

  useEffect(() => {
    verifyEmailAddress(token);
  }, []);

  return (
    <View inverse>
      <ContentsWrapper
        id='emailVerification'
        title='Verifying your email address'
        className='halfwidth'
        inverseHeader
        headingIcon={EmailSvg}
      >
        <Spinner variant='inverse' fillParent loading />
      </ContentsWrapper>
    </View>
  );
};

EmailVerification.propTypes = {
  verifyEmailAddress: PropTypes.func.isRequired,
  location: PropTypes.object,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      verifyEmailAddress: scenarioActions.requestEmailVerification,
    },
    dispatch,
  );

export default compose(connect(null, mapDispatchToProps))(EmailVerification);
