import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import OktaSignIn from '@okta/okta-signin-widget';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';

import View from 'components/View/View';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';

import { getSignInConfig } from 'lib/okta';

import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import './WidgetStyle.nonmodule.css';

export const MyCRMLogin = (props) => {
  const {
    headingTitle,
    headingIcon,
    handleSubmit,
    centeredTitle,
    showFooter,
    recoveryToken,
    stateToken,
    fullPageSpinnerLoading,
  } = props;
  const [altTitle, setAltTitle] = useState('');
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();

  useEffect(() => {
    if (fullPageSpinnerLoading !== false) {
      return;
    }
    const signInConfig = getSignInConfig(history, recoveryToken, stateToken);
    const widget = new OktaSignIn(signInConfig);
    widget
      .showSignInToGetTokens()
      .then(async (tokens) => {
        await oktaAuth.tokenManager.setTokens(tokens);
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });

    widget.on('afterRender', (context) => {
      if (context.controller === 'enroll-choices') {
        setAltTitle('What would you like to use for extra security?');
      } else if (typeof context.controller === 'function') {
        setAltTitle('Let’s log you in securely');
      }
    });

    oktaAuth.authStateManager.subscribe(async (authState) => {
      if (authState.isAuthenticated) {
        const origUrl = oktaAuth.getOriginalUri();
        handleSubmit(origUrl);
      }
    });

    return () => {
      oktaAuth.authStateManager.unsubscribe();
      return widget.remove();
    };
  }, [fullPageSpinnerLoading]);

  return (
    <View inverse showFooter={showFooter}>
      <ContentsWrapper
        id='emailPasswordLogin'
        title={altTitle || headingTitle}
        headingIcon={headingIcon}
        inverseHeader
        className='halfwidth'
        centeredTitle={centeredTitle}
      >
        <div id='sign-in-widget' />
      </ContentsWrapper>
    </View>
  );
};

MyCRMLogin.propTypes = {
  headingTitle: PropTypes.string,
  headingIcon: PropTypes.node,
  handleSubmit: PropTypes.func,
  centeredTitle: PropTypes.bool,
  showFooter: PropTypes.bool,
  recoveryToken: PropTypes.string,
  stateToken: PropTypes.string,
};

export default MyCRMLogin;
