import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { noop } from 'lodash';

import scenarioActions from 'actions/scenarioActions';
import UIActions from 'actions/UIActions';

import * as scenarioSelectors from 'selectors/scenarioSelectors';

import { getDefaultExistingProperty } from 'shared/lib/propertyHelper';
import Questions from 'lib/Questions';
import { toForkOrSignup, afterExistingProperty } from 'lib/pathHelper';

import {
  LOCALITY,
  REQUIRED,
  GREATER_THAN_ZERO,
  AT_LEAST_TWENTY_THOUSAND,
} from 'constants/validators';
import {
  BUYER_SCENARIO_MOVE,
  BUYER_SCENARIO_INVEST,
} from 'shared/constants/loanScenarios';

import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';
import sanityCheck from 'hocs/sanityCheck';

import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import View from 'components/View/View';
import ButtonNext from 'components/ButtonNext/ButtonNext';
import Property from 'components/Property/Property';
import { DEFAULT_EXISTING_PROPERTY_ID } from 'shared/constants/defaults';

const propsTransformForQuestion = (props) => ({
  ...props.scenario.properties[DEFAULT_EXISTING_PROPERTY_ID],
  scenarioName: props.scenario.name,
  isRefinanceOrRenovate: props.isRefinanceOrRenovate,
  seekingBetterDeal: props.scenario.refinanceReasons.betterDeal,
  id: DEFAULT_EXISTING_PROPERTY_ID,
});

const questionSet = (property) => {
  const questions = new Questions();
  const mortgageAmountQuestion = ['mortgageAmount', REQUIRED];
  if (property.seekingBetterDeal) {
    mortgageAmountQuestion.push(AT_LEAST_TWENTY_THOUSAND);
  }

  questions.addBranch(
    ['locality', LOCALITY],
    ['value', REQUIRED],
    mortgageAmountQuestion,
  );
  if (property.isRefinanceOrRenovate) {
    questions.add(['ownerOccupied', REQUIRED]);
  }
  if (property.scenarioName === BUYER_SCENARIO_INVEST) {
    questions.add(['rentalAmount', REQUIRED, GREATER_THAN_ZERO]);
  }
  questions.add('linkButton');
  return questions.arrayOfQuestions();
};

export class ExistingPropertyComponent extends Component {
  static propTypes = {
    scenario: PropTypes.object.isRequired,
    prefetchNextPathData: PropTypes.func.isRequired,
    goToPathWithAnimation: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    isRefinanceOrRenovate: PropTypes.bool.isRequired,
    ...manageQuestionsPropTypes,
  };

  static questionsToAsk = questionSet;
  static revealMethod = 'steps';
  static revealOverBranch = false;

  static isRequiredStructure = (props) =>
    !!getDefaultExistingProperty(props.scenario);

  static titleCopy(scenarioName) {
    if (scenarioName === BUYER_SCENARIO_MOVE) {
      return 'Ok, let’s start by talking about your old home.';
    } else if (scenarioName === BUYER_SCENARIO_INVEST) {
      return 'Please tell us about your current home.';
    }
    return 'So that we can find a better deal, tell us about the property you’re refinancing.';
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSubmit = () => {
    const {
      scenario,
      goToPathWithAnimation,
      prefetchNextPathData,
      isRefinanceOrRenovate,
    } = this.props;

    if (isRefinanceOrRenovate) {
      goToPathWithAnimation(toForkOrSignup());
    } else {
      goToPathWithAnimation(afterExistingProperty(scenario.name));
    }
    prefetchNextPathData();
  };

  render() {
    const {
      scenario,
      setCurrentQuestionTo,
      setCurrentQuestionToNextQuestion,
      questions,
      formCompleted,
      currentQuestion,
    } = this.props;
    let property = getDefaultExistingProperty(scenario);
    property = {
      ...property,
      id: DEFAULT_EXISTING_PROPERTY_ID,
    };

    return (
      <View>
        <ContentsWrapper
          isForm
          formCompleted={formCompleted}
          onSubmit={this.handleSubmit}
          id='existingProperty'
          title={ExistingPropertyComponent.titleCopy(scenario.name)}
          onFocusLost={setCurrentQuestionTo(undefined)}
        >
          <Property
            scenarioName={scenario.name}
            key={property.id}
            property={property}
            setCurrentQuestionToNextQuestion={setCurrentQuestionToNextQuestion}
            currentQuestion={currentQuestion}
            questions={questions}
          />
          <ButtonNext
            {...questions.linkButton}
            id='submitPropertyButton'
            disabled={!formCompleted}
            onClick={this.handleSubmit}
          />
        </ContentsWrapper>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  scenario: scenarioSelectors.scenario(state),
  isRefinanceOrRenovate: scenarioSelectors.getIsRefinanceOrRenovate(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestCashFlowData: scenarioActions.requestCashFlowData,
      goToPathWithAnimation: UIActions.goToPathWithAnimation,
    },
    dispatch,
  );

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  prefetchNextPathData: () => {
    const { scenario } = stateProps;

    if (scenario.name === BUYER_SCENARIO_INVEST) {
      // request cash flow
      dispatchProps.requestCashFlowData({
        scenario,
        propertyId: DEFAULT_EXISTING_PROPERTY_ID,
      });
    } else {
      // no-op
      noop();
    }
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  sanityCheck,
  manageQuestions,
)(ExistingPropertyComponent, propsTransformForQuestion);
